import { CartItem } from "../types/Generic";
export enum UiVersion {
  DEMO,
  DEFAULT,
  STANDARD,
}
export enum ItemTypes {
  Drink,
  Food,
}

export enum ItemClickAction {
  Add,
  Remove,
}

export const EndPoints = {
  ALL: {
    ITEMS: "/client",
  },
  DRINK: {
    CATEGORIES: "/drink_category",
    CATEGORY: "/drink_category",
    DRINKS: "/drink/drinks",
    DRINK: "/drink/",
    USER_SETTINGS: "/drink/",
  },
  FOOD: {
    CATEGORY: "/food_category",
  },
  ORDER: {
    SUBMIT: "/order/",
    GET_ALL: (clientId: string, mtId: string) =>
      `/client/orders/${clientId}/${mtId}`,
  },
  ANALYTICS: {
    CLIENT_ACTIVITY: "/analytics/client-activity",
  },
  WAITLIST: {
    SUBMIT: "/wait-list",
  },
};
export const getItemName = (itemType: number) =>
  itemType === ItemTypes.Food ? "Food" : "Drink";

export const getItemsInCart = (cartItems: CartItem[]) => {
  if (cartItems.length) {
    return cartItems
      .map((item) => item.count)
      .reduce((prev, current) => prev + current);
  }
  return 0;
};
export const calculateCartItemPrice = (
  currencyPrefix: string,
  price: number,
  itemCount: number
): string => {
  let itemPrice = `${currencyPrefix}${price.toLocaleString()}`;
  if (itemCount > 1) {
    itemPrice = itemPrice.concat(` x${itemCount}`);
  }

  return itemPrice;
};

export const getURLParams = (searchParams: string[]) => {
  const searchString = window.location.search;
  const urlParams = new URLSearchParams(searchString);
  const retrievedParams: { [key: string]: string } = {};

  searchParams.forEach((param) => {
    if (urlParams.has(param)) {
      retrievedParams[param] = urlParams.get(param) || "";
    }
  });

  return retrievedParams;
};
