import React, { useEffect, useState, useCallback } from "react";
import { Box, Grid, useMediaQuery } from "@mui/material";
import heroBanner from "../../../assets/bannerv3.jpg";
import { BusinessInfo } from "./ui/BusinessInfo";
import { Banner } from "./ui/banner";
import TopBar from "../../components/TopBar";
import ProductFilter from "./ui/ProductFilter";
import ProductCard from "./ui/ProductCard";
import { motion } from "framer-motion";
import { Category, ProductInterface } from "../../../types/Generic";
import { RootState } from "../../../redux/store";
import CartBubble from "./ui/cart/CartBubble";
import CartModal from "./ui/cart/CartModal";
import OrderModal from "./ui/OrderModal";
import { useSelector } from "react-redux";
import { useThemeContext } from "../../../theme/ThemeContext";

export const HomePage: React.FC = () => {
  const [showTopBar, setShowTopBar] = useState(false);
  const [filteredProducts, setFilteredProducts] = useState<ProductInterface[]>(
    []
  );
  const [isCartModalOpen, setIsCartModalOpen] = useState(false);
  const [isOrderModal, setIsOrderModal] = useState(false);

  const handleCartBubbleClick = () => {
    setIsCartModalOpen(true);
  };
  const handleOrderClick = () => {
    setIsOrderModal(true);
  };
  const handleCloseOrder = () => {
    setIsOrderModal(false);
  };
  const handleCloseCartModal = () => {
    setIsCartModalOpen(false);
  };

  const { theme } = useThemeContext();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [activeCategories, setActiveCategories] = useState<Category[]>([]);
  const cart = useSelector((store: RootState) => store.Items.cart);

  const productCount = cart.reduce((acc, item) => acc + item.count, 0);

  const handleScroll = useCallback(() => {
    const bannerHeight =
      document.querySelector("#hero-banner")?.clientHeight || 0;
    const scrollTop = window.scrollY || document.documentElement.scrollTop;
    setShowTopBar(scrollTop >= bannerHeight);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  useEffect(() => {
    if (productCount < 1 && document.fullscreenElement) {
      document.exitFullscreen();
    }
  }, [productCount]);

  const topBarVariants = {
    hidden: { y: "-100%", opacity: 0 },
    visible: { y: 0, opacity: 1, transition: { duration: 0.5 } },
  };

  const handleFilterChange = useCallback(
    (filteredProducts: ProductInterface[], activeCategories: Category[]) => {
      setFilteredProducts(filteredProducts);
      setActiveCategories(activeCategories);
    },
    []
  );

  return (
    <section
      style={{
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
      }}
    >
      <div className="onesignal-customlink-container" />

      <div>
        <div id="hero-banner">
          <Box component="header" sx={{ minHeight: "35vh" }}>
            <Banner
              bgImage={heroBanner}
              height={"35vh"}
              orderClick={handleOrderClick}
            />
          </Box>
          <BusinessInfo />
        </div>

        {showTopBar && (
          <motion.div
            initial="hidden"
            animate="visible"
            variants={topBarVariants}
            className="fixed top-0 z-50 w-full"
          >
            <Box>
              <TopBar orderClick={handleOrderClick} />
            </Box>
          </motion.div>
        )}
        <Box
          component="div"
          sx={{
            transition: "padding-top 0.3s ease",
            minWidth: "100%",
            backgroundColor: "background.default",
          }}
          className={`${
            showTopBar ? `fixed top-0 pt-20 ` : "relative"
          } maxContainer px-[5%]  w-full z-20`}
        >
          <ProductFilter
            height={`${showTopBar ? "79px" : "109px"}`}
            onFilterChange={handleFilterChange}
          />
        </Box>
      </div>

      <Box
        className={`${showTopBar ? "mt-72" : "mt-0"} w-full min-h-96 px-[5%]`}
      >
        <Grid container spacing={isMobile ? 0 : 2}>
          {filteredProducts.map((product) => {
            const category = activeCategories.find(
              (cat) => cat.id === product.categoryId
            );
            return (
              <Grid item xs={12} sm={6} md={4} lg={3} key={product.id}>
                <ProductCard product={product} categoryLabel={category?.name} />
              </Grid>
            );
          })}
        </Grid>
      </Box>

      {productCount > 0 && (
        <>
          <CartBubble onClick={handleCartBubbleClick} />
          <CartModal
            openCart={isCartModalOpen}
            closeCart={handleCloseCartModal}
          />
        </>
      )}
      {isOrderModal && (
        <OrderModal openOrder={isOrderModal} closeOrder={handleCloseOrder} />
      )}
    </section>
  );
};

export default HomePage;
