import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from "react";
import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { ItemTypes } from "../../../../helpers/Constants";
import { Category, ProductInterface } from "../../../../types/Generic";
import food from "../../../../assets/food.jpeg";
import drink from "../../../../assets/drink.jpeg";
import { productFilterClickHandler } from "../../../../v2/utils/filterUtils";
import FilterCategory from "../../../components/FilterCategory";
import FilterItem from "../../../components/FilterItem";

interface ProductFilterProps {
  height?: string;
  onFilterChange: (
    filteredProducts: ProductInterface[],
    activeCategories: Category[]
  ) => void;
}

const ProductFilter: React.FC<ProductFilterProps> = ({
  height,
  onFilterChange,
}) => {
  const dispatch = useDispatch();
  const activeFilter = useSelector((store: RootState) => store.Items.itemType);
  const allProducts = useSelector(
    (store: RootState) => store.Items.categorizedProducts
  );
  const highlightedFilter = useSelector(
    (store: RootState) => store.Items.highlightedFilter
  );

  const [activeProduct, setActiveProduct] = useState<Category[]>([]);
  const [activeCategory, setActiveCategory] = useState<string>("all");
  const categoryRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);

  const activeProductMemoized = useMemo(() => {
    return activeFilter === ItemTypes.Food
      ? allProducts.food
      : allProducts.drink;
  }, [activeFilter, allProducts.drink, allProducts.food]);

  const handleCategoryChange = useCallback(
    (selectedCategory: string) => {
      setActiveCategory(selectedCategory);
    },
    [setActiveCategory]
  );

  useEffect(() => {
    setActiveProduct(activeProductMemoized);
    setActiveCategory("all");
  }, [activeProductMemoized]);

  useEffect(() => {
    const filtered =
      activeCategory === "all"
        ? activeProduct.flatMap((category) => category.product)
        : activeProduct.find((category) => category.id === activeCategory)
            ?.product || [];
    onFilterChange(filtered, activeProduct);
  }, [activeCategory, activeProduct, onFilterChange]);

  const labeledCategories = useMemo(() => {
    return [
      { id: "all", label: "All" },
      ...activeProductMemoized.map((category) => ({
        id: category.id,
        label: category.name,
      })),
    ];
  }, [activeProductMemoized]);

  useEffect(() => {
    if (highlightedFilter && scrollContainerRef.current) {
      const category = labeledCategories.find(
        (category) => category.label === highlightedFilter
      );
      if (category) {
        const categoryElement = categoryRefs.current[category.id];
        if (categoryElement) {
          const categoryRect = categoryElement.getBoundingClientRect();
          const containerRect =
            scrollContainerRef.current.getBoundingClientRect();
          const scrollLeft =
            categoryElement.offsetLeft -
            (containerRect.width - categoryRect.width) / 2;

          scrollContainerRef.current.scrollTo({
            left: scrollLeft,
            behavior: "smooth",
          });
        }
      }
    }
  }, [highlightedFilter, labeledCategories, scrollContainerRef, categoryRefs]);

  return (
    <Box component="div">
      <Box className="flex justify-center gap-[3%] py-4">
        <FilterItem
          isActive={activeFilter === ItemTypes.Drink}
          onClick={() =>
            productFilterClickHandler(ItemTypes.Drink, dispatch, allProducts)
          }
          backgroundImage={drink}
          height={height}
        >
          Drinks
        </FilterItem>
        <FilterItem
          isActive={activeFilter === ItemTypes.Food}
          onClick={() =>
            productFilterClickHandler(ItemTypes.Food, dispatch, allProducts)
          }
          backgroundImage={food}
          height={height}
        >
          Food
        </FilterItem>
      </Box>
      <Box
        ref={scrollContainerRef}
        sx={{ p: 1 }}
        className="flex gap-5 overflow-x-auto "
      >
        {labeledCategories.map((category) => (
          <FilterCategory
            key={category.id}
            ref={(el) => (categoryRefs.current[category.id] = el)}
            isActive={category.label !== highlightedFilter}
            isHighlighted={highlightedFilter === category.label}
            onClick={() => handleCategoryChange(category.id)}
          >
            {category.label.toUpperCase()}
          </FilterCategory>
        ))}
      </Box>
    </Box>
  );
};

export default ProductFilter;
