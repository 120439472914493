import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

export default function TopAppBar() {
  const brandName = useSelector((store: RootState) => store.Client.user.name);
  return (
    <AppBar
      component="nav"
      sx={{ height: 70, backgroundColor: "primary.main" }}
    >
      <Toolbar className="sticky top-0 flex items-center justify-center h-full text-white">
        <Typography variant="h6" component="div">
          {brandName?.toUpperCase()}
        </Typography>
      </Toolbar>
    </AppBar>
  );
}
