import { createBrowserRouter, RouterProvider } from "react-router-dom";
import RootLayout from "./RootLayout";
import React, { Suspense } from "react";
import { ProductsPage } from "./pages/products/products.page";
import { loadData } from "./utils/loaderUtils";
import { CheckoutPage } from "./pages/checkout/checkout.page";
import { FavoritesPage } from "./pages/favorites/favorites.page";
import { store } from "../redux/store";
import { HomePage } from "./pages/home/home.page";
import { ThemeContextProvider } from "../theme/ThemeContext";
import { LandingPage } from "../landing/landing.page";
import { getUserBaseSlug } from "./utils/pathUtils";
import NotFound from "../NotFound";

const router = () => {
  const { user, tableNumber } = store.getState().Client;
  const baseSlug = getUserBaseSlug(user, tableNumber);

  return createBrowserRouter([
    {
      path: "/",
      element: <LandingPage />,
      errorElement: <NotFound />,
    },
    {
      path: baseSlug,
      element: <HomePage />,
    },
    {
      path: `${baseSlug}/app`,
      element: <RootLayout />,
      children: [
        {
          path: "home",
          element: <HomePage />,
          loader: () => {
            return loadData();
          },
        },
        {
          path: "products",
          element: <ProductsPage />,
          loader: () => {
            return loadData();
          },
        },
        {
          path: "cart",
          element: <CheckoutPage />,
          loader: () => {
            const activeProductCategories =
              store.getState().Items.activeProductCategories;
            if (!activeProductCategories.length) return loadData();
            return null;
          },
        },
        {
          path: "favorites",
          element: (
            <Suspense fallback={"Loading"}>
              <FavoritesPage />
            </Suspense>
          ),
          loader: () => {
            const activeProductCategories =
              store.getState().Items.activeProductCategories;
            if (!activeProductCategories.length) return loadData();
            return null;
          },
        },
      ],
    },
  ]);
};

export const AppV2Router = () => {
  return (
    <ThemeContextProvider>
      <RouterProvider router={router()} />
    </ThemeContextProvider>
  );
};
