import React from "react";
import TopAppBar from "./components/TopAppBar";
import { StickyBottomNav } from "./components/BottomAppBar";
import Box from "@mui/material/Box";
import { Outlet } from "react-router-dom";
import { useThemeContext } from "../theme/ThemeContext";

export default function RootLayout() {
  const { theme } = useThemeContext();

  return (
    <main
      style={{
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
      }}
    >
      <TopAppBar />
      <Box sx={{ height: 80, width: "100%" }}></Box>
      <Box component="main" className="min-h-[80vh]">
        <Outlet />
      </Box>
      <Box sx={{ height: 100, width: "100%" }}></Box>
      <StickyBottomNav />
    </main>
  );
}
