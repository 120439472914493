import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import * as React from "react";
import Drawer from "@mui/material/Drawer";

const navItems = ["Home", "About", "Contact"];

interface NavContentProps {
  handleDrawerToggle: () => void;
}

const NavContent = (props: NavContentProps) => {
  return (
    <Box onClick={props.handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        Menutender
      </Typography>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item} disablePadding>
            <ListItemButton sx={{ textAlign: "center" }}>
              <ListItemText primary={item} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

interface Props {
  handleDrawerToggle: () => void;
  mobileOpen: boolean;
}

const MobileNav = (props: Props) => {
  const { handleDrawerToggle, mobileOpen } = props;

  return (
    <nav>
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: 220,
          },
        }}
      >
        <NavContent handleDrawerToggle={props.handleDrawerToggle} />
      </Drawer>
    </nav>
  );
};

export default MobileNav;
