import React from "react";
import { Box } from "@mui/material";

interface FilterItemProps {
  isActive: boolean;
  children: React.ReactNode;
  onClick: () => void;
  backgroundImage: string;
  height?: string;
}

const FilterItem: React.FC<FilterItemProps> = ({
  isActive,
  children,
  onClick,
  height = "105px",
  backgroundImage,
}) => {
  return (
    <Box
      onClick={onClick}
      className="w-full transition-all duration-300 ease-in-out transform hover:scale-105"
      sx={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        borderRadius: "30px",
        cursor: "pointer",
        position: "relative",
        padding: "10px 15px",
        maxWidth: "365px",
        height,
        display: "flex",
        alignItems: "end",
        justifyContent: "center",
        textTransform: "uppercase",
        color: "#fff",
        fontWeight: "bold",
        fontSize: "20px",
        textShadow: "0 0 5px rgba(0,0,0,0.5)",
        ...(isActive && {
          border: "5px solid #C34F5A",
        }),
        "&:hover": {
          opacity: 0.9,
        },
      }}
    >
      <Box
        className="flex items-center justify-center"
        sx={{
          width: "100%",
          height: "48px",
          backgroundColor: "rgba(0,0,0,0.8)",
          borderRadius: "30px",
          ...(isActive && {
            backgroundColor: "rgba(195, 79, 90, 0.8)",
          }),
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default FilterItem;
