import React from "react";
import IconButton from "@mui/material/IconButton";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import { useThemeContext } from "./ThemeContext";

const ToggleTheme = () => {
  const { themeMode, toggleTheme } = useThemeContext();

  return (
    <IconButton onClick={toggleTheme} sx={{ padding: "0px" }}>
      {themeMode === "dark" ? (
        <LightModeOutlinedIcon className="text-primary" />
      ) : (
        <DarkModeOutlinedIcon className="text-primary" />
      )}
    </IconButton>
  );
};

export default ToggleTheme;
