import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import {
  cartPriceTotal,
  clearCart,
  getCartCount,
  orderSubmit,
} from "../../../helpers/ActionHelpers";
import { Box, Button, Container, Grid } from "@mui/material";
import { ItemInCart } from "./ui/ItemInCart";
import { OrderSubmitState } from "../../../redux/slices/Items";
import ConfirmOrder, { Confirm } from "./ui/ConfirmOrder";
import React, { useState } from "react";
import { EmptyPage } from "../../components/EmptyPage";
import { Footer } from "./ui/Footer";

export const CheckoutPage = () => {
  const cart = useSelector((store: RootState) => store.Items.cart);
  const orderSubmitState = useSelector(
    (store: RootState) => store.Items.orderSubmitState
  );
  const { currency, canPlaceOrder, id, username } = useSelector(
    (store: RootState) => store.Client.user
  );
  const tableNumber = useSelector(
    (store: RootState) => store.Client.tableNumber
  );
  const [openConfirm, setOpenConfirm] = useState(false);
  const dispatch = useDispatch();

  const cartCount = getCartCount(cart);
  if (!cartCount) {
    return (
      <EmptyPage
        title={"Your Order List is Empty"}
        description={"Looks like you haven't made your choice yet"}
      />
    );
  }
  const priceTotal = cartPriceTotal(cart);
  const handleConfirmDialog = (response: number) => {
    setOpenConfirm(false);
    if (response === Confirm.Confirm) {
      orderSubmit(cart, id, username, dispatch, tableNumber);
    }
  };
  const handleSubmit = () => setOpenConfirm(true);
  const disableActions = ![
    OrderSubmitState.Failed,
    OrderSubmitState.Stale,
  ].includes(orderSubmitState);

  return (
    <Container>
      <ConfirmOrder
        open={openConfirm}
        handleConfirm={handleConfirmDialog}
        orderPrice={priceTotal}
      />
      <Box textAlign="right" mt={1}>
        <Button
          color="error"
          variant="outlined"
          onClick={() => clearCart(cart, dispatch)}
        >
          Clear
        </Button>
      </Box>
      <Grid container>
        {cart.map((item) => {
          return (
            <ItemInCart
              key={item.id}
              cartItem={item}
              disableActions={disableActions}
            />
          );
        })}
      </Grid>
      <Box textAlign="center" mt={5}>
        <Footer
          buttonClickDisabled={disableActions}
          canPlaceOrder={canPlaceOrder}
          currencyPrefix={currency.prefix}
          orderTotal={priceTotal}
          orderSubmitState={orderSubmitState}
          handleSubmit={handleSubmit}
        />
      </Box>
    </Container>
  );
};
