import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { palette } from "../color";
import SavingsOutlinedIcon from "@mui/icons-material/SavingsOutlined";
import SavingsIcon from "@mui/icons-material/Savings";
import { Box, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

type StyleProps = {
  expanded: boolean;
  isMobile?: boolean;
};
const FeatureWrapper = styled(Box)((props: StyleProps) => ({
  border: props.expanded ? "" : `0.2px solid ${palette.primary}`,
  backgroundColor: props.expanded
    ? palette.primaryLighter(0.7)
    : palette.primaryLighter(0.7), // tweak later
  transition: "background-color 0.9s ease",
  borderRadius: "10px",
  margin: "20px 0",
  cursor: "pointer",
  padding: `20px ${props.isMobile ? "10px" : "40px"}`,
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  "& .expand-icon": {
    // color: props.expanded ? "white" : palette.primary,
    fontSize: props.isMobile ? "25px" : "35px",
    fontWeight: 500,
  },
  ":hover": {
    backgroundColor: props.expanded ? "" : palette.primary,
    "& .expand-icon": {
      color: "white",
    },
  },
}));

const FeatureIconBox = styled(Box)((props: StyleProps) => ({
  height: props.isMobile ? "40px" : "50px",
  width: props.isMobile ? "40px" : "50px",
  borderRadius: "50%",
  // backgroundColor: props.expanded ? "white" : palette.primary,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  "& .icon": {
    color: props.expanded ? palette.primary : "white",
  },
}));

const FeatureTitle = styled(Box)((props: StyleProps) => ({
  marginLeft: props.isMobile ? "10px" : "20px",
}));

interface Props {
  title: string;
  description: string;
  contained?: boolean;
  isMobile?: boolean;
}

const Feature = (props: Props) => {
  const { isMobile, title, description } = props;
  const [hovered, setHovered] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const featureIcon = hovered ? (
    <SavingsIcon className="icon" />
  ) : (
    <SavingsOutlinedIcon className="icon" />
  );

  const expandIcon = expanded ? (
    <RemoveIcon className="expand-icon" />
  ) : (
    <AddIcon className="expand-icon" />
  );
  /**
   * Todo:
   * 1. Use the viewport variable from landing page to set specific font sizes per viewports
   * */
  return (
    <FeatureWrapper
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={() => setExpanded(!expanded)}
      expanded={expanded}
      isMobile={!!isMobile}
    >
      <FeatureIconBox expanded={expanded} isMobile={!!isMobile}>
        {featureIcon}
      </FeatureIconBox>
      <FeatureTitle isMobile={!!isMobile} expanded={expanded}>
        <Typography
          sx={{ fontWeight: 500, fontSize: isMobile ? "18px" : "21px" }}
        >
          {title}
        </Typography>
      </FeatureTitle>
      <Box sx={{ marginLeft: "auto" }}>{expandIcon}</Box>
      {expanded && <Box sx={{ width: "100%" }}>{description}</Box>}
    </FeatureWrapper>
  );
};

export default Feature;
