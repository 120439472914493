import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { Language } from "../../types/Generic";
import { UiVersion } from "../../helpers/Constants";

export enum WaitListSubmitState {
  Stale,
  Requested,
  Successful,
  Failed,
}
export interface User {
  id: string;
  currency: {
    prefix: string;
    name: string;
  };
  languages: Array<Language>;
  activities: Array<string>;
  defaultLang: string;
  name: string;
  address: string;
  brief: string;
  contacts: string;
  username: string;
  segment: string;
  hasSegments: boolean;
  canPlaceOrder: boolean;
  theme: number;
  clientVersion: UiVersion;
  wifi?: string;
}
export interface ClientState {
  user: User;
  activeLanguage: Language;
  tableNumber: number;
  waitlistSubmitState: number;
}

const initialState: ClientState = {
  user: {
    id: "",
    theme: UiVersion.DEMO,
    currency: { prefix: "", name: "" },
    defaultLang: "",
    name: "",
    address: "",
    brief: "",
    username: "",
    segment: "",
    contacts: "",
    languages: [],
    activities: [],
    hasSegments: false,
    canPlaceOrder: false,
    clientVersion: UiVersion.DEFAULT,
  },
  activeLanguage: { name: "", iso: "" },
  tableNumber: 1,
  waitlistSubmitState: WaitListSubmitState.Stale,
};

const ClientSlice = createSlice({
  name: "Client",
  initialState,
  reducers: {
    setUser: (
      state,
      action: PayloadAction<{ user: User; tableNum: number }>
    ) => {
      const userSettings = action.payload.user;
      const defaultLanguage = userSettings.languages.find(
        (lang) => lang.iso === userSettings.defaultLang
      );
      if (defaultLanguage) {
        state.activeLanguage = defaultLanguage;
      } else {
        state.activeLanguage = defaultLanguage ||
          userSettings.languages[0] || { name: "", iso: "" };
      }

      state.user = action.payload.user;
      state.tableNumber = action.payload.tableNum;
    },
    setSelectedLanguage: (state, action: PayloadAction<Language>) => {
      state.activeLanguage = action.payload;
    },
    setWaitlistSubmitState: (
      state,
      action: PayloadAction<WaitListSubmitState>
    ) => {
      state.waitlistSubmitState = action.payload;
    },
  },
});

export const { setUser, setSelectedLanguage, setWaitlistSubmitState } =
  ClientSlice.actions;
export default ClientSlice.reducer;
