import React, { useState } from "react";
import {
  Box,
  Button,
  IconButton,
  Tooltip,
  tooltipClasses,
  Typography,
} from "@mui/material";
import logo from "../../../../assets/threadman-logo.png";
import CheckIcon from "@mui/icons-material/Check";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import CustomModal from "../../../../components/modal/CustomModalv3";
import InterpreterModeOutlinedIcon from "@mui/icons-material/InterpreterModeOutlined";
import { setSelectedLanguage } from "../../../../redux/slices/Client";
import { Language } from "../../../../types/Generic";
import { useThemeContext } from "../../../../theme/ThemeContext";
import ToggleTheme from "../../../../theme/ToggleThemeBtn";

export const BusinessInfo = () => {
  const businessInfo = useSelector((state: RootState) => state.Client.user);
  const activeLanguage = useSelector(
    (state: RootState) => state.Client.activeLanguage
  );
  const [isModalOpen, setModalOpen] = useState(false);
  const [languageModalOpen, setLanguageModalOpen] = useState(false);
  const { themeMode } = useThemeContext();
  const dispatch = useDispatch();

  const handleOpen = () => {
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  const handleLanguageOpen = () => {
    setLanguageModalOpen(true);
  };

  const handleLanguageClose = () => {
    setLanguageModalOpen(false);
  };

  const handleLanguageSelect = (language: Language) => {
    dispatch(setSelectedLanguage(language));
    setLanguageModalOpen(false);
  };
  return (
    <Box
      component="section"
      sx={{
        padding: "50px 20px 0px",
        position: "relative",
      }}
    >
      <Box className="flex flex-col items-center justify-center mb-[15px]">
        <Typography
          variant="h2"
          fontSize="30px"
          fontWeight="700"
          component="h2"
          className="pt-5 pb-1 text-center"
        >
          {businessInfo.name}
        </Typography>

        <Box
          component="div"
          className="flex items-center justify-center gap-1 mt-1 "
        >
          <Typography
            variant="body2"
            component="p"
            className={`${themeMode === "dark" ? "text-darkmodegrey" : "text-lightmodegrey"}`}
          >
            Mon - Fri (10am - 12pm)
          </Typography>
          <Button
            onClick={handleOpen}
            sx={{
              textTransform: "none",
              fontWeight: "600",
              color: "primary.main",
              padding: "0px 8px",
            }}
          >
            More
          </Button>

          <Tooltip
            title="Change Language"
            arrow
            placement="top-start"
            slotProps={{
              popper: {
                sx: {
                  [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]:
                    {
                      marginBottom: "0px",
                    },
                },
              },
            }}
          >
            <IconButton
              onClick={handleLanguageOpen}
              sx={{ padding: "0px 8px" }}
            >
              <InterpreterModeOutlinedIcon
                fontSize="small"
                sx={{ padding: "0px" }}
                className="text-primary hover:text-opacity-80 hover:shadow-lg"
              />
            </IconButton>
          </Tooltip>
          <ToggleTheme />
        </Box>
      </Box>

      <Box
        className="absolute top-[-70px] left-1/2 transform -translate-x-1/2 flex items-center justify-center bg-primary"
        sx={{
          width: 120,
          height: 120,
          borderRadius: 5,
          overflow: "hidden",
        }}
      >
        <img src={logo} alt="Logo" className="object-contain w-24 h-24" />
      </Box>

      <CustomModal
        open={isModalOpen}
        onClose={handleClose}
        bg="background.default"
      >
        <Typography
          variant="h3"
          component="h3"
          className={`${themeMode === "dark" ? " text-white" : "text-black"} pb-5`}
        >
          {businessInfo.name}
        </Typography>
        <Typography
          variant="body1"
          component="p"
          className={`${themeMode === "dark" ? "text-darkmodegrey" : "text-lightmodegrey"} text-[14px] font-normal pb-5`}
        >
          {businessInfo.brief}
        </Typography>
        <Box component="div" className="pb-5">
          <Typography
            variant="h4"
            component="h4"
            fontWeight="600"
            fontSize="20px"
            className={`${themeMode === "dark" ? " text-white" : "text-black"}  pb-2`}
          >
            Location
          </Typography>
          <Typography
            variant="body2"
            component="p"
            className={`${themeMode === "dark" ? "text-darkmodegrey" : "text-lightmodegrey"}  text-[14px] font-normal`}
          >
            {businessInfo.address}
          </Typography>
        </Box>

        <Box component="div" className="pb-5">
          <Typography
            variant="h4"
            component="h4"
            fontWeight="600"
            fontSize="20px"
            className={`${themeMode === "dark" ? " text-white" : "text-black"}  pb-2`}
          >
            Opening hours
          </Typography>
          <Typography
            variant="body2"
            component="p"
            className={`${themeMode === "dark" ? "text-darkmodegrey" : "text-lightmodegrey"}  text-[14px] font-normal`}
          >
            Mon - Fri (10am - 12pm)
          </Typography>
        </Box>

        <Box component="div">
          <Typography
            variant="h4"
            component="h4"
            fontWeight="600"
            fontSize="20px"
            className={`${themeMode === "dark" ? " text-white" : "text-black"}  pb-2`}
          >
            Contact
          </Typography>
          <Typography
            variant="body2"
            component="p"
            className={`${themeMode === "dark" ? "text-darkmodegrey" : "text-lightmodegrey"}  text-[14px] font-normal`}
          >
            {businessInfo.contacts}
          </Typography>
        </Box>
      </CustomModal>

      <CustomModal
        open={languageModalOpen}
        onClose={handleLanguageClose}
        bg="background.default"
      >
        <Box
          component="div"
          className={`w-full border-b ${themeMode === "dark" ? "border-dimgrey" : "border-[#bfbfbf]"} `}
        >
          <Typography
            variant="h3"
            component="h3"
            className={`${themeMode === "dark" ? " text-white" : "text-black"} pb-10`}
          >
            Menu language
          </Typography>

          <Typography
            variant="h6"
            component="p"
            className={`${themeMode === "dark" ? " text-white" : "text-black"} pb-3`}
          >
            Available menu languages
          </Typography>
        </Box>
        {businessInfo.languages.map((language) => (
          <div className="w-full" key={language.name}>
            <Box
              component="div"
              className={`w-full border-b ${themeMode === "dark" ? "border-dimgrey" : "border-[#bfbfbf]"}  flex items-center justify-between cursor-pointer py-4`}
              onClick={() => handleLanguageSelect(language)}
            >
              <Typography
                component="span"
                className={`${themeMode === "dark" ? " text-white" : "text-black"}  text-[14px] font-medium`}
              >
                {language.name}
              </Typography>
              {activeLanguage.iso === language.iso && (
                <IconButton sx={{ padding: 0 }}>
                  <CheckIcon className="text-primary" fontSize="medium" />
                </IconButton>
              )}
            </Box>
            <div
              className={`${activeLanguage.iso === language.iso ? "block" : "hidden"} w-full border-b border-[#eeeeee5b] pt-5 pb-4`}
            >
              <Typography
                variant="h6"
                component="p"
                className={`${themeMode === "dark" ? " text-white" : "text-black"} pb-2`}
              >
                Restaurant translation
              </Typography>
              <Typography
                component="span"
                className={`${themeMode === "dark" ? "text-darkmodegrey" : "text-lightmodegrey"}  text-[14px]`}
              >
                These restaurant translations are not always accurate. if you
                have serious allergies, contact the restaurant before ordering.
              </Typography>
            </div>
          </div>
        ))}
      </CustomModal>
    </Box>
  );
};
