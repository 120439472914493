import { Container, Grid } from "@mui/material";
import Product from "./ui/Product";
import ProductFilter from "./ui/Filter";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import React, { Fragment } from "react";

export const ProductsPage = () => {
  const activeCategory = useSelector(
    (state: RootState) => state.Items.activeProductCategories
  );
  return (
    <Container component="div" className="px-[5%] w-full">
      <Grid
        container
        sx={{
          position: "sticky",
          top: 70,
          zIndex: 2,
          marginBottom: "8px",
          backgroundColor: "background.default",
        }}
      >
        <ProductFilter />
      </Grid>
      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
      >
        {activeCategory.map((category) => {
          const product = category.product;
          if (!product.length) return <Fragment key={category.id}></Fragment>;
          return product.map((product) => (
            <Product product={product} key={product.id} />
          ));
        })}
      </Grid>
    </Container>
  );
};
