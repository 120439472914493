import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { ItemTypes } from "../../../../helpers/Constants";
import {
  categoryChangeHandler,
  OptionType,
  productFilterClickHandler,
} from "../../../utils/filterUtils";
import React, { useEffect, useState } from "react";
import { Category } from "../../../../types/Generic";

interface FilterItemProps {
  isActive: boolean;
  children: React.ReactNode;
  onClick: () => void;
}
const MainFilterItem = styled((props: FilterItemProps) => {
  const { isActive, ...other } = props;
  return <Paper {...other} />;
})(({ theme, isActive }) => ({
  ...theme.typography.body2,
  marginTop: "10px",
  padding: theme.spacing(2),
  textAlign: "center",
  fontWeight: "700",
  backgroundColor: isActive ? "#A4454E" : "#fff",
  color: isActive ? "#fff" : "#A4454E",
}));

export default function ProductFilter() {
  const dispatch = useDispatch();
  const activeFilter = useSelector((store: RootState) => store.Items.itemType);
  const allProducts = useSelector(
    (store: RootState) => store.Items.categorizedProducts
  );
  const [activeProduct, setActiveProduct] = useState<Category[]>([]);
  const [labeledCategories, setLabeledCategories] = useState<OptionType[]>([
    { id: "", label: "" },
  ]);
  const [activeLabeledCategory, setActiveLabeledCategory] =
    useState<OptionType>({
      id: "",
      label: "",
    });

  useEffect(() => {
    const product =
      activeFilter === ItemTypes.Food ? allProducts.food : allProducts.drink;
    let labeledCategory: OptionType[] = [{ label: "All", id: "" }];
    product.forEach((category) => {
      if (category.product.length) {
        labeledCategory.push({ label: category.name, id: category.id });
      }
    });
    setActiveProduct(product);
    setLabeledCategories(labeledCategory);
  }, [activeFilter, allProducts.drink, allProducts.food]);

  return (
    <Grid container spacing={2} sx={{ padding: "0px" }}>
      <Grid item xs={6}>
        <MainFilterItem
          isActive={activeFilter === ItemTypes.Drink}
          onClick={() =>
            productFilterClickHandler(ItemTypes.Drink, dispatch, allProducts)
          }
        >
          Drinks
        </MainFilterItem>
      </Grid>
      <Grid item xs={6}>
        <MainFilterItem
          isActive={activeFilter === ItemTypes.Food}
          onClick={() =>
            productFilterClickHandler(ItemTypes.Food, dispatch, allProducts)
          }
        >
          Food
        </MainFilterItem>
      </Grid>
      <Grid container item xs={12} sx={{ justifyContent: "center" }}>
        <FormControl sx={{ width: "100%", color: "text.secondary" }}>
          <InputLabel id="demo-simple-select-helper-label">Category</InputLabel>
          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            value={activeLabeledCategory.label}
            label="Age"
            onChange={(e) =>
              categoryChangeHandler(
                e.target.value,
                setActiveLabeledCategory,
                labeledCategories,
                dispatch,
                activeProduct,
                activeFilter
              )
            }
            sx={{ color: "text.primary" }}
          >
            {labeledCategories.map((labeledCategory) => (
              <MenuItem key={labeledCategory.id} value={labeledCategory.label}>
                {labeledCategory.label}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText
            sx={{
              color: "text.secondary",
              textAlign: "center",
              padding: "20px 0",
            }}
          >
            Select a category to filter products
          </FormHelperText>
        </FormControl>
      </Grid>
    </Grid>
  );
}
