import { Paper, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

type BannerProps = {
  bgImage: any;
  action: {
    shouldNavigate: boolean;
    path?: string;
  };
  text: string;
  height?: string;
};
export const Banner = (props: BannerProps) => {
  const { bgImage, action, height, text } = props;
  const navigate = useNavigate();

  return (
    <Paper
      sx={{
        backgroundImage: `url(${bgImage})`,
        backgroundSize: "cover",
        borderRadius: 0,
        backgroundRepeat: "no-repeat",
        height: height || "100px",
        // margin: "15px 0",
        textAlign: "center",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
      }}
      onClick={() => {
        if (action.shouldNavigate && action.path) {
          navigate(action.path);
        }
      }}
    >
      <Typography
        sx={{ textShadow: "2px 2px white", color: "primary.main" }}
        variant="h5"
      >
        {text}
      </Typography>
    </Paper>
  );
};
