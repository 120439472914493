//@ts-nocheck
import React, { FC, useEffect, useState, CSSProperties, useRef } from "react";
import {
  AppBar,
  IconButton,
  Toolbar,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { useSelector } from "react-redux";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { RootState } from "../../../../redux/store";
import CustomModal from "../../../../components/modal/CustomModalv3";
import { money } from "../../../../helpers/ActionHelpers";
import { useThemeContext } from "../../../../theme/ThemeContext";
import Slider from "react-slick";
import { Back, Next } from "../../../../assets/icon-svg";

interface OrderModalProps {
  openOrder: boolean;
  closeOrder: () => void;
}
const OrderModal: FC<OrderModalProps> = ({ openOrder, closeOrder }) => {
  const brandName = useSelector((store: RootState) => store.Client.user.name);

  const customerOrders = useSelector(
    (store: RootState) => store.Items.customerOrders
  );

  const activeLanguage = useSelector(
    (store: RootState) => store.Client.activeLanguage
  );
  const currency = useSelector(
    (store: RootState) => store.Client.user.currency
  );
  const [timeLeft, setTimeLeft] = useState(300);
  const { themeMode } = useThemeContext();
  const [currentSlide, setCurrentSlide] = useState(0);
  const sliderRef = useRef<any | null>(null);

  const next = () => {
    sliderRef.slickNext();
  };
  const previous = () => {
    sliderRef.slickPrev();
  };

  const arrowStyles: CSSProperties = {
    position: "absolute",
    zIndex: 5,
    width: 30,
    height: 30,
    cursor: "pointer",
  };

  const settings = {
    dots: false,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    beforeChange: (current, next) => setCurrentSlide(next),
    prevArrow: (
      <button type="button" style={arrowStyles} onClick={previous}>
        <div className="absolute z-[99999px] p-2 rounded-full top-1/2 sm:p-4 bg-primary left-10 ">
          {Back}
        </div>
      </button>
    ),
    nextArrow: (
      <button type="button" style={arrowStyles} onClick={next}>
        <div className="absolute p-2 rounded-full sm:p-4 bg-primary right-10 top-1/2">
          {Next}
        </div>
      </button>
    ),
  };

  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
      return () => clearTimeout(timer);
    }
  }, [timeLeft]);

  const minutes = Math.floor(timeLeft / 60);
  const seconds = timeLeft % 60;

  return (
    <CustomModal
      open={openOrder}
      onClose={closeOrder}
      height={"100%"}
      padding={"0px 0px 30px"}
      radius={"0"}
      bg="background.default"
      showCloseButton={false}
      sx={{
        boxSizing: "border-box",
        viewportFit: "cover",
        position: "relative",
      }}
    >
      <AppBar
        component="nav"
        sx={{ minHeight: 70, backgroundColor: "primary.main" }}
      >
        <Toolbar className="fixed top-0 z-10 flex items-center justify-center w-screen h-full p-2 text-white bg-primary min-h-16">
          <IconButton
            aria-label="close"
            onClick={closeOrder}
            sx={{
              position: "absolute",
              left: 5,
              top: 10,
              zIndex: 1000,
              backgroundColor: "#262626",
              borderRadius: "50%",
              "&:hover": {
                backgroundColor: "#666666",
              },
              color: "white",
            }}
          >
            <ArrowBackIcon sx={{ fontSize: "24px" }} />
          </IconButton>
          <div>
            <Typography variant="h4" component="h5" className="text-center">
              Your Order
            </Typography>
            <Typography variant="body1" component="p" className="text-center">
              {brandName?.toUpperCase()}
            </Typography>
          </div>
        </Toolbar>
      </AppBar>
      <div className="relative w-full h-20 top-20" />

      {customerOrders.length > 1 ? (
        <Slider ref={sliderRef} {...settings} className="slider-component">
          {customerOrders
            .slice()
            .reverse()
            .map((item, index) => (
              <div
                key={index}
                className="flex flex-col items-center justify-center w-full h-full py-10"
              >
                <Box
                  component="div"
                  className="flex items-center justify-center mx-auto mb-4 bg-dimPrimary"
                  sx={{
                    width: "150px",
                    height: "150px",
                    borderRadius: "100%",
                  }}
                >
                  <Typography
                    variant="h6"
                    component="h6"
                    className="tracking-wider"
                  >
                    {minutes}:{seconds < 10 ? `0${seconds}` : seconds} mins
                  </Typography>
                </Box>
                <Typography
                  variant="body2"
                  component="p"
                  className={`${
                    themeMode === "dark" ? " text-white" : " text-black"
                  } tracking-wider`}
                >
                  We are processing your order...
                </Typography>

                <div
                  className={`w-full mx-auto max-w-[346px] sm:max-w-[396px] ${
                    themeMode === "dark" ? " text-white" : " text-black"
                  } pt-10 order-list px-[7%]`}
                >
                  <List sx={{ width: "100%" }}>
                    {item.order.map((order, idx) => {
                      const productName =
                        order.names[activeLanguage.iso] || order.names.en;
                      return (
                        <ListItem
                          sx={{ padding: "10px 0px" }}
                          className="flex justify-between w-full gap-5"
                          key={idx}
                        >
                          <ListItemText
                            primary={`${productName} ${
                              order.count > 1 ? ` (${order.count})` : ""
                            }`}
                          />
                          <ListItemText
                            primary={order.price.toLocaleString()}
                            sx={{ textAlign: "right" }}
                          />
                        </ListItem>
                      );
                    })}

                    <div className="flex justify-between w-full gap-5 p-2 border-y">
                      <Typography
                        variant="h6"
                        component="p"
                        className={`${
                          themeMode === "dark" ? " text-white" : " text-black"
                        }`}
                      >
                        Total
                      </Typography>
                      <Typography
                        variant="h5"
                        component="p"
                        className={`${
                          themeMode === "dark" ? " text-white" : " text-black"
                        }`}
                      >
                        {money(item.orderTotal, currency.prefix)}
                      </Typography>
                    </div>
                  </List>
                </div>
              </div>
            ))}
        </Slider>
      ) : (
        customerOrders.map((item, index) => (
          <div
            key={index}
            className="flex flex-col items-center justify-center w-full h-full py-10"
          >
            <Box
              component="div"
              className="flex items-center justify-center mx-auto mb-4 bg-dimPrimary"
              sx={{
                width: "150px",
                height: "150px",
                borderRadius: "100%",
              }}
            >
              <Typography
                variant="h6"
                component="h6"
                className="tracking-wider"
              >
                {minutes}:{seconds < 10 ? `0${seconds}` : seconds} mins
              </Typography>
            </Box>
            <Typography
              variant="body2"
              component="p"
              className={`${
                themeMode === "dark" ? " text-white" : " text-black"
              } tracking-wider`}
            >
              We are processing your order...
            </Typography>

            <div
              className={`w-full mx-auto max-w-[356px] ${
                themeMode === "dark" ? " text-white" : " text-black"
              } pt-10 order-list px-[7%]`}
            >
              <List sx={{ width: "100%" }}>
                {item.order.map((order, idx) => {
                  const productName =
                    order.names[activeLanguage.iso] || order.names.en;
                  return (
                    <ListItem
                      sx={{ padding: "10px 0px" }}
                      className="flex justify-between w-full gap-5"
                      key={idx}
                    >
                      <ListItemText
                        primary={`${productName} ${
                          order.count > 1 ? ` (${order.count})` : ""
                        }`}
                      />
                      <ListItemText
                        primary={order.price.toLocaleString()}
                        sx={{ textAlign: "right" }}
                      />
                    </ListItem>
                  );
                })}

                <div className="flex justify-between w-full gap-5 p-2 border-y">
                  <Typography
                    variant="h6"
                    component="p"
                    className={`${
                      themeMode === "dark" ? " text-white" : " text-black"
                    }`}
                  >
                    Total
                  </Typography>
                  <Typography
                    variant="h5"
                    component="p"
                    className={`${
                      themeMode === "dark" ? " text-white" : " text-black"
                    }`}
                  >
                    {money(item.orderTotal, currency.prefix)}
                  </Typography>
                </div>
              </List>
            </div>
          </div>
        ))
      )}

      {customerOrders.length > 1 && (
        <div className="mt-4 text-center">
          <Typography
            variant="body2"
            className={`${themeMode === "dark" ? " text-white" : " text-black"}`}
          >
            {currentSlide + 1}/{customerOrders.length}
          </Typography>
        </div>
      )}
    </CustomModal>
  );
};

export default OrderModal;
