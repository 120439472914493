import React from "react";
import { styled } from "@mui/material/styles";

interface SectionProps {
  contain: boolean;
}
const StyledSection = styled("section")((props: SectionProps) => ({
  ...(props.contain && { margin: "0 85px" }),
  ...(!props.contain && { margin: "10px 0" }),
}));

interface Props {
  children: React.ReactNode;
  title: string;
  contain?: boolean;
}

const Section = (props: Props) => {
  const { title, contain, children } = props;

  return (
    <StyledSection title={title} contain={!!contain}>
      {children}
    </StyledSection>
  );
};

export default Section;
