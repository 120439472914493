import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import React, { useEffect, useState } from "react";
import { ProductInterface } from "../../../types/Generic";
import Product from "../products/ui/Product";
import { Container, Grid } from "@mui/material";
import { EmptyPage } from "../../components/EmptyPage";

export const FavoritesPage = () => {
  let favorites = useSelector((store: RootState) => store.Items.favorites);
  if (!favorites) favorites = [];
  const foodAndDrinks = useSelector(
    (store: RootState) => store.Items.categorizedProducts
  );
  const [favoriteProducts, setFavoriteProducts] = useState<
    Array<ProductInterface>
  >([]);

  useEffect(() => {
    const mergedCategories = [...foodAndDrinks.food, ...foodAndDrinks.drink];
    const products: Array<ProductInterface> = [];
    mergedCategories.forEach((category) => {
      category.product.forEach((product) => products.push(product));
    });
    const filteredFavorites = products.filter((product) =>
      favorites.includes(product.id)
    );
    setFavoriteProducts(filteredFavorites);
  }, [favorites, foodAndDrinks.drink, foodAndDrinks.food]);

  if (!favoriteProducts.length) {
    return (
      <EmptyPage
        title="Your Favorites List is Empty"
        description="Feel free to add your favorite items. We will keep them here for you."
      />
    );
  }

  return (
    <Container component="div" className="px-[5%] py-12 w-full">
      <Grid container spacing={1}>
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          {favoriteProducts.map((product) => (
            <Product product={product} key={product.id} />
          ))}
        </Grid>
      </Grid>
    </Container>
  );
};
