import { Button } from "@mui/material";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import IconButton from "@mui/material/IconButton";
import { Add, Remove } from "@mui/icons-material";
import { addItemClick } from "../../../../helpers/ActionHelpers";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { ItemClickAction } from "../../../../helpers/Constants";
import { CartItem, ProductInterface } from "../../../../types/Generic";
import { Dispatch } from "@reduxjs/toolkit";

interface AddAndRemoveProps {
  product: ProductInterface | CartItem;
  dispatch: Dispatch<any>;
  cart: any;
  productCount: number;
  disableActions: boolean;
}
export const AddToCartButton = (props: AddAndRemoveProps) => {
  const { product, dispatch, cart } = props;
  const screenWidth = window.screen.width;
  let buttonSize: "small" | "medium" | "large" = "medium";
  if (screenWidth <= 475) {
    buttonSize = "small";
  }
  if (screenWidth >= 700) {
    buttonSize = "large";
  }
  return (
    <Box sx={{ width: "100%" }} textAlign={"center"}>
      <Button
        variant="contained"
        size={buttonSize}
        sx={{ fontSize: { xs: "14px", sm: "unset" } }}
        className="whitespace-nowrap"
        startIcon={<AddShoppingCartIcon />}
        onClick={(e) => {
          e.stopPropagation();
          addItemClick(product, dispatch, cart, ItemClickAction.Add);
        }}
      >
        Add To Cart
      </Button>
    </Box>
  );
};

export const ItemAddRemoveInCart = (props: AddAndRemoveProps) => {
  const { product, dispatch, cart, productCount, disableActions } = props;
  return (
    <>
      <IconButton
        aria-label="share"
        onClick={(e) => {
          e.stopPropagation();
          addItemClick(product, dispatch, cart, ItemClickAction.Remove);
        }}
        disabled={disableActions}
        sx={{
          width: "30px",
          height: "30px",
          backgroundColor: "primary.main",
          color: "#fff",
        }}
      >
        <Remove />
      </IconButton>
      <Box sx={{ flexGrow: 1 }} />
      <IconButton aria-label="number of product in cart">
        <Typography fontSize="medium" color="#fff">
          {productCount}
        </Typography>
      </IconButton>
      <Box sx={{ flexGrow: 1 }} />
      <IconButton
        aria-label="share"
        onClick={(e) => {
          e.stopPropagation();
          addItemClick(product, dispatch, cart, ItemClickAction.Add);
        }}
        disabled={disableActions}
        sx={{
          width: "30px",
          height: "30px",
          backgroundColor: "primary.main",
          color: "#fff",
        }}
      >
        <Add />
      </IconButton>
    </>
  );
};

export const ProductActions = (props: AddAndRemoveProps) => {
  const { productCount } = props;
  if (productCount) {
    return <ItemAddRemoveInCart {...props} />;
  }

  return <AddToCartButton {...props} />;
};
