import React, { forwardRef } from "react";
import { Box } from "@mui/material";
import { useThemeContext } from "../../theme/ThemeContext";

interface FilterCategoryProps {
  isActive: boolean;
  children: React.ReactNode;
  onClick: () => void;
  isHighlighted: boolean;
}

const FilterCategory = forwardRef<HTMLDivElement, FilterCategoryProps>(
  ({ isActive, isHighlighted, onClick, children }, ref) => {
    const { themeMode } = useThemeContext();
    return (
      <Box
        ref={ref}
        onClick={onClick}
        className={`transition-all ease-in-out duration-300 transform hover:scale-105 cursor-pointer rounded-[20px] whitespace-nowrap w-full p-2 min-w-[159px] shadow-sm hover:bg-opacity-90 flex flex-col justify-center text-center font-medium text-[18px]
                   ${isActive && "border-primary"} 
                   ${
                     themeMode === "dark"
                       ? isHighlighted
                         ? "bg-primary text-white"
                         : "bg-gray-300 text-black"
                       : isHighlighted
                         ? "bg-primary text-white"
                         : "bg-gray-600 text-[#e4e5f1]"
                   }`}
      >
        {children}
      </Box>
    );
  }
);

export default FilterCategory;
