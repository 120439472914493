import { StickyBottomNav } from "../../components/BottomAppBar";
import React from "react";
import { Box, Container } from "@mui/material";
import { BusinessInfo } from "./ui/BusinessInfo";
import { BusinessActivities } from "./ui/BusinessActivities";
import { Banner } from "./ui/Banner";
import drink from "../../../../src/assets/drink.jpeg";
import defaultBanner from "../../../../src/assets/default-banner.jpg";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import ToggleTheme from "../../../theme/ToggleThemeBtn";
import { useThemeContext } from "../../../theme/ThemeContext";

export const HomePage = () => {
  const user = useSelector((state: RootState) => state.Client.user);
  const tableNumber = useSelector(
    (state: RootState) => state.Client.tableNumber
  );
  const baseSlug = `/${user.username}/${user.segment}/${tableNumber}`;
  const { theme } = useThemeContext();
  //todo: load user banner from db

  return (
    <section
      style={{
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
      }}
    >
      <Box component="header" sx={{ minHeight: "15vh" }}>
        <Banner
          action={{ shouldNavigate: false }}
          bgImage={defaultBanner}
          height={"200px"}
          text={""}
        />
      </Box>
      <Container sx={{ padding: "30px 5%" }}>
        <div className="flex justify-center w-full">
          <ToggleTheme />
        </div>
        <BusinessInfo />
        <Banner
          action={{ shouldNavigate: true, path: `${baseSlug}/app/products` }}
          bgImage={drink}
          text="Go to menu"
        />
        <BusinessActivities />
        <Box sx={{ height: "56px", width: "100%" }}></Box>
        <StickyBottomNav />
      </Container>
    </section>
  );
};
