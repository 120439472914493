import React, { FC } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  Typography,
  Button,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import CustomButton from "../../../../components/common/CustomButton";
import { OrderSubmitState } from "../../../../../redux/slices/Items";

interface ConfirmModalProps {
  isOpen: boolean;
  handleClose: () => void;
  orderSubmitState: OrderSubmitState;
  onConfirm: () => void;
  onCancel: () => void;
}

const ConfirmModal: FC<ConfirmModalProps> = ({
  isOpen,
  handleClose,
  orderSubmitState,
  onConfirm,
  onCancel,
}) => {
  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
      PaperProps={{
        sx: {
          backgroundColor: "background.default",
          color: "text.primary",
          display: "flex",
          justifyContent: "center",
          borderRadius: "16px",
        },
      }}
    >
      {orderSubmitState === OrderSubmitState.Requested && (
        <DialogContent className="flex items-center justify-center min-h-60">
          <CircularProgress sx={{ color: "primary.main" }} size="80px" />
        </DialogContent>
      )}

      {orderSubmitState === OrderSubmitState.Failed && (
        <>
          <DialogTitle
            className="flex items-center justify-center gap-3 tracking-widest"
            sx={{ paddingTop: "25px" }}
          >
            <Typography variant="h5" component="h5">
              Order Failed
            </Typography>
            <CancelIcon sx={{ color: "#B00020" }} />
          </DialogTitle>
          <DialogContent className="flex justify-center">
            <Typography className="text-center">
              Please try again or contact an attendant
            </Typography>
          </DialogContent>
          <DialogActions
            sx={{
              display: "flex",
              justifyContent: "center",
              paddingBottom: "25px",
            }}
          >
            <CustomButton onClick={handleClose}>Okay</CustomButton>
          </DialogActions>
        </>
      )}

      {orderSubmitState === OrderSubmitState.Successful && (
        <>
          <DialogTitle
            className="flex items-center justify-center gap-3 tracking-widest"
            sx={{ paddingTop: "25px" }}
          >
            <Typography variant="h5" component="h5">
              Order Successful
            </Typography>
            <CheckCircleIcon sx={{ color: "#4BB543" }} />
          </DialogTitle>
          <DialogContent className="flex justify-center">
            <Typography className="text-center">
              Click the my order button on the top bar to see your order status
            </Typography>
          </DialogContent>
          <DialogActions
            sx={{
              display: "flex",
              justifyContent: "center",
              paddingBottom: "25px",
            }}
          >
            <CustomButton onClick={handleClose}>Okay</CustomButton>
          </DialogActions>
        </>
      )}

      {orderSubmitState === OrderSubmitState.Stale && (
        <>
          <DialogTitle>Confirm Order</DialogTitle>
          <DialogContent>
            <Typography>Are you sure you want to place the order?</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={onCancel} color="error">
              Cancel
            </Button>
            <Button onClick={onConfirm} sx={{ color: "primary.light" }}>
              Confirm
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default ConfirmModal;
