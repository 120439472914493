import React from "react";
import { Box, Typography } from "@mui/material";
import LandingBanner from "./styled-components/LandingBanner";
import Navigation from "./styled-components/Navigation";
import SectionHeader from "./styled-components/SectionHeader";
import Section from "./styled-components/Section";
import {
  getViewport,
  useWindowSize,
  viewports,
} from "../helpers/ActionHelpers";
import Feature from "./styled-components/Feature";
import Button from "@mui/material/Button";
import { palette } from "./color";
import WaitlistModal from "./WaitlistModal";
import { manageModal, MODAL_TRIGGER } from "../redux/slices/Items";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";

export const LandingPage = () => {
  const windowSize = useWindowSize();
  const viewport = getViewport(windowSize.width);
  const isMobileViewports = ![viewports.DESKTOP, viewports.DESKTOP_XL].includes(
    viewport
  );
  const modal = useSelector((state: RootState) => state.Items.modal);
  const dispatch = useDispatch();

  const handleOpenModal = () => {
    dispatch(manageModal({ isOpen: true, triggeredBy: MODAL_TRIGGER.CLOSE }));
  };
  const handleCloseModal = () => {
    dispatch(manageModal({ isOpen: false, triggeredBy: MODAL_TRIGGER.CLOSE }));
  };
  return (
    <Box>
      <section title="menutender-base-landing">
        <LandingBanner contain={isMobileViewports}>
          <Navigation />
        </LandingBanner>
        <Box sx={{ height: "20vh" }}>
          <Typography textAlign="center" p={2}>
            Launching January 2025. Pre-register for a premium launch offer.
            <br />
            <Button
              variant="contained"
              size={"large"}
              onClick={handleOpenModal}
              sx={{
                backgroundColor: palette.secondary,
                color: "white",
                marginTop: "25px",
                ":hover": {
                  backgroundColor: palette.secondaryLighter(0.8),
                },
              }}
            >
              Join Waitlist
            </Button>
          </Typography>
        </Box>
      </section>

      <Section contain={!isMobileViewports} title="">
        <SectionHeader />
        <Box
          sx={{
            padding: `5px ${isMobileViewports ? "50px" : "180px"}`,
          }}
        >
          <Feature
            isMobile={viewport === viewports.MOBILE}
            title={"Product Management"}
            description={"Create and manage your products"}
          />
          <Feature
            isMobile={viewport === viewports.MOBILE}
            title={"QR Menu"}
            description={
              "Say goodbye to menu printing costs. All your customers need to do is scan a QR code and your menu is presented to them"
            }
          />
          <Feature
            isMobile={viewport === viewports.MOBILE}
            title={"Order Placement"}
            description={
              "Customers become irritated when they wait. Save time by accepting orders instantly."
            }
          />
          <Feature
            isMobile={viewport === viewports.MOBILE}
            title={"Segmentation"}
            description={
              "One product different prices. Segment your product pricing into different categories that meets your restaurant's layout"
            }
          />
          <Feature
            isMobile={viewport === viewports.MOBILE}
            title={"Staff Management"}
            description={
              "Create employee acocunts and manage their access to your products."
            }
          />
          <Feature
            isMobile={viewport === viewports.MOBILE}
            title={"Promo Builder"}
            description={
              "Combine multiple products to make an offer to your customers."
            }
          />
        </Box>
      </Section>
      <WaitlistModal open={modal.isOpen} onClose={handleCloseModal} />
    </Box>
  );
};
