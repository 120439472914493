import React from "react";
import { Box } from "@mui/material";
import { palette } from "./landing/color";

export default function NotFound() {
  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
        alignContent: "center",
        color: palette.primary,
      }}
    >
      <Box
        sx={{
          fontWeight: 600,
          fontSize: "30px",
        }}
      >
        404 | Yikes!
      </Box>
      <Box
        sx={{
          fontWeight: 300,
          fontSize: "18px",
        }}
      >
        We could not find that page.
      </Box>
    </Box>
  );
}
