import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const Title = styled(Typography)({
  display: "inline-block",
  position: "relative",
  paddingBottom: "25px",
  fontSize: "40px",
  "&::after": {
    content: '""',
    position: "absolute",
    left: "50px",
    bottom: 0,
    width: "50%",
    height: "4px",
    paddingBottom: "4px",
    backgroundColor: "rgb(164, 69, 78)",
  },
});

const Description = styled(Typography)({
  padding: "25px 0",
});

const SectionHeader = () => {
  const title = "Features";
  const description =
    "Reduce your business operation costs. Let Menutender do the run around for you";

  return (
    <Box
      sx={{
        textAlign: "center",
      }}
    >
      <Title>{title}</Title>
      <Description>{description}</Description>
    </Box>
  );
};

export default SectionHeader;
