import React, { useEffect, useRef, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { addItemClick, money } from "../../../../helpers/ActionHelpers";
import { RootState } from "../../../../redux/store";
import { ProductInterface } from "../../../../types/Generic";
import CustomModal from "../../../../components/modal/CustomModalv3";
import CustomButton from "../../../components/common/CustomButton";

import {
  AddInCart,
  ItemAddRemoveInCart,
  RemoveInCart,
} from "./ItemAddRemoveInCart";
import { ItemClickAction } from "../../../../helpers/Constants";
import { setHighlightedFilter } from "../../../../redux/slices/Items";
import UseInView from "../../../components/UseInView";
import { useThemeContext } from "../../../../theme/ThemeContext";

interface ProductCardProps {
  product: ProductInterface;
  categoryLabel?: string | any;
}

const ProductCard: React.FC<ProductCardProps> = ({
  product,
  categoryLabel,
}) => {
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const cart = useSelector((store: RootState) => store.Items.cart);
  const user = useSelector((store: RootState) => store.Client.user);
  const { themeMode } = useThemeContext();

  const productCount =
    cart.find((cartItem) => cartItem.id === product.id)?.count || 0;
  const activeLanguage = useSelector(
    (store: RootState) => store.Client.activeLanguage
  );

  const cardRef = useRef<HTMLDivElement>(null);
  const isInView = UseInView(cardRef);

  const productPrice = user.hasSegments
    ? product.prices[user.segment]
    : product.price;

  useEffect(() => {
    if (isInView) {
      dispatch(setHighlightedFilter(categoryLabel));
    }
  }, [isInView, categoryLabel, dispatch]);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const currency = useSelector(
    (store: RootState) => store.Client.user.currency
  );

  const handleAddToOrderClick = () => {
    const productWithPrice: ProductInterface = {
      ...product,
      price: productPrice,
    };
    if (!productCount) {
      addItemClick(productWithPrice, dispatch, cart, ItemClickAction.Add);
    }
    handleCloseModal();
  };

  const productName = product.names[activeLanguage.iso] || product.names.en;
  const productDescription =
    product.descriptions[activeLanguage.iso] || product.descriptions.en;

  return (
    <>
      <Box
        ref={cardRef}
        onClick={handleOpenModal}
        component="div"
        className={`border-b ${themeMode === "dark" ? "border-dimgrey" : "border-[#bfbfbf]"} cursor-pointer flex items-center gap-[10PX] px-[10px] py-5
        `}
      >
        <Box
          component="img"
          src={product.productPhoto?.secureUrl}
          alt={product.names[activeLanguage.iso]}
          sx={{
            width: "100px",
            height: "100px",
            borderRadius: "16px",
          }}
        />
        <Box sx={{ flex: 1 }}>
          <Typography variant="h6" component="h2" className="mb-2">
            {productName.toUpperCase()}
          </Typography>
          <Typography
            variant="body2"
            className={`line-clamp-2 ${themeMode === "dark" ? "text-[#b5b5b5]" : "text-[#666]"}`}
          >
            {productDescription}
          </Typography>
          <Box className="flex items-center justify-between gap-2 mt-2">
            <Typography variant="h6">
              {money(productPrice, currency.prefix)}
            </Typography>

            <ItemAddRemoveInCart
              product={product}
              dispatch={dispatch}
              cart={cart}
              productCount={productCount}
              userSegment={user.segment}
            />
          </Box>
        </Box>
      </Box>

      <CustomModal
        open={openModal}
        onClose={handleCloseModal}
        bg="background.default"
      >
        <div className="flex flex-col items-center ">
          <Box
            sx={{ backgroundColor: "background.default" }}
            className="rounded-ss-[30px] rounded-se-[30px] flex items-center justify-center py-5 absolute top-0 w-full max-h-full"
          >
            <Box
              component="img"
              src={product.productPhoto?.secureUrl}
              alt={product.names[activeLanguage.iso]}
              className="object-cover w-full bg-black max-w-40 rounded-2xl "
            />
          </Box>

          <div className="w-full h-40" />

          <Box className="w-full py-5">
            <Typography
              variant="h4"
              component="h4"
              className={`${themeMode === "dark" ? "text-white" : "text-black"} pb-2`}
            >
              {productName.toUpperCase()}
            </Typography>
            <Typography
              variant="body1"
              className={`${themeMode === "dark" ? "text-[#ccc]" : "text-[#666]"} pb-2`}
            >
              {productDescription}
            </Typography>

            <Typography
              variant="h5"
              sx={{ fontWeight: "700" }}
              className=" text-dimPrimary"
            >
              {money(productPrice, currency.prefix)}
            </Typography>
          </Box>

          <div className="flex w-full gap-3 sm:gap-5">
            <CustomButton
              component="div"
              className="flex w-full gap-5"
              size="large"
              sx={{ padding: "15px 20px", justifyContent: "space-between" }}
            >
              <RemoveInCart
                product={product}
                dispatch={dispatch}
                cart={cart}
                productCount={productCount}
                userSegment={user.segment}
              />
              <Typography variant="h6" component="h6">
                {productCount}
              </Typography>
              <AddInCart
                product={product}
                dispatch={dispatch}
                cart={cart}
                productCount={productCount}
                userSegment={user.segment}
              />
            </CustomButton>
            <CustomButton
              component="div"
              sx={{ padding: "15px 20px" }}
              className="flex justify-between w-full gap-2 whitespace-nowrap"
              size="large"
              onClick={handleAddToOrderClick}
            >
              Add to order
              <Typography sx={{ fontSize: "12px", fontWeight: "600" }}>
                {productCount > 0 &&
                  money(productPrice * productCount, currency.prefix)}
              </Typography>
            </CustomButton>
          </div>
        </div>
      </CustomModal>
    </>
  );
};

export default ProductCard;
