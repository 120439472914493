import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React from "react";
import StarIcon from "@mui/icons-material/Star";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";

export const BusinessActivities = () => {
  const businessActivities = useSelector(
    (state: RootState) => state.Client.user.activities
  );
  return (
    <Box component="section" sx={{ margin: "20px 0" }}>
      {businessActivities && (
        <>
          <Typography
            variant="h5"
            sx={{
              marginBottom: "18px",
              textDecoration: "underline wavy #C34F5A",
              textUnderlineOffset: "10px",
            }}
          >
            Activities
          </Typography>

          <List>
            <Grid container spacing={1} sx={{ padding: "10px 0" }}>
              {businessActivities.map((activity) => (
                <Grid item xs={6} key={activity}>
                  <ListItem disablePadding>
                    <ListItemIcon>
                      <StarIcon sx={{ color: "primary.main" }} />
                    </ListItemIcon>
                    <ListItemText primary={activity} />
                  </ListItem>
                </Grid>
              ))}
            </Grid>
          </List>
        </>
      )}
    </Box>
  );
};
