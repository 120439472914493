import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { AppV2Router } from "./v2/router";
import { initializeApp } from "./v2/utils/loaderUtils";
import { UiVersion } from "./helpers/Constants";
import { AppV3Router } from "./v3/router";
import NotFound from "./NotFound";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const renderApplication = (version: UiVersion) => {
  let rootApp: JSX.Element;
  switch (version) {
    case UiVersion.STANDARD:
      rootApp = <AppV3Router />;
      break;
    case UiVersion.DEFAULT:
      rootApp = <AppV2Router />;
      break;
    case UiVersion.DEMO: // todo remove
    default:
      rootApp = <NotFound />;
  }

  return root.render(
    <Suspense>
      <React.StrictMode>
        <Provider store={store}>{rootApp}</Provider>
      </React.StrictMode>
    </Suspense>
  );
};

initializeApp().then((theme) => {
  return renderApplication(theme);
});
