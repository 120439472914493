import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import React from "react";
import { palette } from "../color";

interface BannerProps {
  containComponent: boolean;
}
const StyledWrapper = styled(Box)((props: BannerProps) => ({
  height: "80vh",
  backgroundColor: palette.primaryLighter(0.3),
  borderBottomRightRadius: "10%",
  ...(props.containComponent && { margin: "15px" }),
}));

interface Props {
  children: React.ReactNode;
  contain: boolean;
}
const LandingBanner = (props: Props) => {
  return (
    <StyledWrapper containComponent={props.contain}>
      {props.children}
    </StyledWrapper>
  );
};

export default LandingBanner;
