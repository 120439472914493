import React from "react";
import { Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { cartPriceTotal, money } from "../../../../../helpers/ActionHelpers";
import { RootState } from "../../../../../redux/store";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import CustomButton from "../../../../components/common/CustomButton";

interface CartBubbleProps {
  onClick?: () => void;
  btnClick?: () => void;
  showCartIcon?: boolean;
}
const CartBubble: React.FC<CartBubbleProps> = ({
  onClick,
  btnClick,
  showCartIcon = true,
}) => {
  const cart = useSelector((store: RootState) => store.Items.cart);
  const currency = useSelector(
    (store: RootState) => store.Client.user.currency
  );
  const userCanAcceptOrder = useSelector(
    (store: RootState) => store.Client.user.canPlaceOrder
  );
  const priceTotal = cartPriceTotal(cart);
  const numericPriceTotal = parseFloat(priceTotal.replace(/,/g, ""));

  return (
    <Box
      zIndex={1000}
      onClick={onClick}
      className="fixed z-50 flex flex-col items-center justify-center text-white rounded-full shadow-lg cursor-pointer w-28 h-28 bg-primary bottom-4 right-4"
    >
      <Typography variant="body1">
        {money(numericPriceTotal, currency.prefix)}
      </Typography>
      {showCartIcon ? (
        <div>
          <ShoppingCartIcon />
        </div>
      ) : (
        <CustomButton
          onClick={btnClick}
          background="transparent"
          disabled={!userCanAcceptOrder}
        >
          {userCanAcceptOrder ? "Order Now" : "Total"}
        </CustomButton>
      )}
    </Box>
  );
};

export default CartBubble;
