import { Dispatch } from "@reduxjs/toolkit";
import { ItemTypes } from "../../helpers/Constants";
import { setActiveCategory } from "../../redux/slices/Items";
import { CategorizedProducts, Category } from "../../types/Generic";
import React from "react";

export interface OptionType {
  id: string;
  label: string;
}
export const productFilterClickHandler = (
  filter: number,
  dispatch: Dispatch<any>,
  allProducts: CategorizedProducts
) => {
  const newActiveProduct =
    filter === ItemTypes.Food ? allProducts.food : allProducts.drink;
  dispatch(
    setActiveCategory({ activeCategory: newActiveProduct, itemType: filter })
  );
};

export const categoryChangeHandler = (
  selectedCategory: string,
  setActiveLabeledCategory: React.Dispatch<React.SetStateAction<OptionType>>,
  labeledCategories: OptionType[],
  dispatch: Dispatch<any>,
  activeProduct: Category[],
  activeProductType: number
) => {
  const selectedLabeledCategory = labeledCategories.filter(
    (option) => option.label === selectedCategory
  )[0];

  if (selectedLabeledCategory) {
    setActiveLabeledCategory(selectedLabeledCategory);
    if (selectedLabeledCategory.label === "All") {
      dispatch(
        setActiveCategory({
          activeCategory: activeProduct,
          itemType: activeProductType,
        })
      );
    } else {
      const newActiveCategory = activeProduct.filter(
        (category) => category.id === selectedLabeledCategory.id
      );

      dispatch(
        setActiveCategory({
          activeCategory: newActiveCategory,
          itemType: activeProductType,
        })
      );
    }
  }
};
