// TopBar.tsx
import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { Box } from "@mui/material";
import orderIcon from "../../assets/orders-icon.svg";
import whiteOrderIcon from "../../assets/whiteorders-icon.svg";
import { useThemeContext } from "../../theme/ThemeContext";

interface Props {
  orderClick?: () => void;
}

const TopBar: React.FC<Props> = ({ orderClick }) => {
  const brandName = useSelector((store: RootState) => store.Client.user.name);
  const customerOrders = useSelector(
    (store: RootState) => store.Items.customerOrders
  );
  const { themeMode } = useThemeContext();

  return (
    <AppBar
      component="nav"
      sx={{ height: 70, backgroundColor: "primary.main", position: "relative" }}
    >
      <Toolbar className="sticky top-0 flex w-full h-full text-white">
        <Typography variant="h6" component="h6" className="w-full text-center">
          {brandName?.toUpperCase()}
        </Typography>{" "}
        {customerOrders.length > 0 && (
          <Box
            component="img"
            src={themeMode === "dark" ? orderIcon : whiteOrderIcon}
            onClick={orderClick}
            alt="order-icon"
            className="transition-all duration-300 ease-in-out transform cursor-pointer hover:scale-105"
            sx={{
              width: "30px",
              height: "30px",
            }}
          />
        )}
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
