import { configureStore } from "@reduxjs/toolkit";
import Items from "./slices/Items";
import Client from "./slices/Client";

export const store = configureStore({
  reducer: {
    Items,
    Client,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

/*
Definition of Reducers
Items:
The items reducer houses products and everything related to it
Client:
The client reducer houses business settings.
* */
