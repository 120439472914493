import { Box, Paper } from "@mui/material";
import { RootState } from "../../../../redux/store";
import { useSelector } from "react-redux";
import orderIcon from "../../../../assets/orders-icon.svg";
import whiteOrderIcon from "../../../../assets/whiteorders-icon.svg";
import { useThemeContext } from "../../../../theme/ThemeContext";

type BannerProps = {
  bgImage: any;
  height?: string;
  orderClick?: () => void;
};
export const Banner = (props: BannerProps) => {
  const { bgImage, height, orderClick } = props;
  const customerOrders = useSelector(
    (store: RootState) => store.Items.customerOrders
  );
  const { themeMode } = useThemeContext();

  return (
    <section className="w-full h-full">
      <Paper
        sx={{
          borderRadius: "0",
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          height: height || "30vh",
        }}
      >
        {customerOrders.length > 0 && (
          <Box
            component="img"
            src={themeMode === "dark" ? whiteOrderIcon : orderIcon}
            onClick={orderClick}
            alt="order-icon"
            className="transition-all duration-300 ease-in-out transform cursor-pointer hover:scale-105"
            sx={{
              width: "50px",
              height: "50px",
              position: "absolute",
              top: "10px",
              right: "10px",
              padding: "5px",
              backgroundColor: "background.default",
              borderRadius: "10px",
              border: "2px solid #c34f5a",
            }}
          />
        )}
      </Paper>
    </section>
  );
};
