import { Grid, Paper, Typography } from "@mui/material";
import { CartItem } from "../../../../types/Generic";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { money } from "../../../../helpers/ActionHelpers";
import { ItemAddRemoveInCart } from "../../products/ui/AddToCartButton";

interface Props {
  cartItem: CartItem;
  disableActions: boolean;
}
export const ItemInCart = (props: Props) => {
  const { cartItem, disableActions } = props;
  const activeLanguage = useSelector(
    (store: RootState) => store.Client.activeLanguage
  );
  const cart = useSelector((store: RootState) => store.Items.cart);
  const dispatch = useDispatch();
  const currency = useSelector(
    (store: RootState) => store.Client.user.currency
  );

  return (
    <Grid item xs={12} component={Paper} mt={2} p={1}>
      <Grid container alignItems="center">
        <Grid item xs={4} sx={{ height: "70px", width: "70px" }}>
          {cartItem.productPhoto && (
            <img
              src={cartItem.productPhoto.secureUrl}
              height={70}
              width={70}
              alt="product"
            />
          )}
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body2" color="#fff">
            {cartItem.names[activeLanguage.iso].toUpperCase()}
          </Typography>
          <Typography component="h6" color="#fff">
            {money(cartItem.price, currency.prefix)}
          </Typography>
        </Grid>
        <ItemAddRemoveInCart
          cart={cart}
          product={cartItem}
          dispatch={dispatch}
          productCount={cartItem.count}
          disableActions={disableActions}
        />
      </Grid>
    </Grid>
  );
};
