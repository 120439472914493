import { styled } from "@mui/system";
import { Container } from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { useThemeContext } from "../../theme/ThemeContext";
import { getUserBaseSlug } from "../utils/pathUtils";

interface Props {
  title: string;
  description: string;
  svg?: JSX.Element;
}
export const EmptyPage = (props: Props) => {
  const user = useSelector((state: RootState) => state.Client.user);
  const tableNumber = useSelector(
    (state: RootState) => state.Client.tableNumber
  );
  const { themeMode } = useThemeContext();
  const baseSlug = getUserBaseSlug(user, tableNumber);
  const { title, description, svg } = props;

  const StyledContainer = styled(Container)({
    display: "flex",
    flexDirection: "column",
    height: "80vh",
    width: "100%",
    alignContent: "center",
    alignItems: "center",
    justifyContent: "center",
  });

  return (
    <StyledContainer>
      {svg}
      <Typography variant="h6" m={2}>
        {title}
      </Typography>
      <Typography
        variant="body1"
        className={`text-center ${
          themeMode === "dark" ? "text-darkmodegrey" : "text-lightmodegrey"
        }`}
      >
        {description}
      </Typography>
      <Box mt={3} className="p-3 text-white rounded-md bg-primary">
        <Link to={`/${baseSlug}/app/products`}>Back to Menu</Link>
      </Box>
    </StyledContainer>
  );
};
