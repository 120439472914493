import { FC } from "react";
import { Box, Typography } from "@mui/material";
import { money } from "../../../../../helpers/ActionHelpers";
import { ItemAddRemoveInCart } from "../ItemAddRemoveInCart";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { CartItem } from "../../../../../types/Generic";
import { useThemeContext } from "../../../../../theme/ThemeContext";

interface CartCardProps {
  product: CartItem;
}

const CartCard: FC<CartCardProps> = ({ product }) => {
  const dispatch = useDispatch();
  const { themeMode } = useThemeContext();
  const cart = useSelector((store: RootState) => store.Items.cart);
  const user = useSelector((store: RootState) => store.Client.user);
  const currency = useSelector(
    (store: RootState) => store.Client.user.currency
  );
  const activeLanguage = useSelector(
    (store: RootState) => store.Client.activeLanguage
  );
  const productInCart = cart.find(
    (cartItem: { id: string }) => cartItem.id === product.id
  );
  const productCount = productInCart?.count || 0;
  const productPrice = user.hasSegments
    ? product.prices?.[user.segment]
    : product.price;
  const productName = product.names[activeLanguage.iso] || product.names.en;

  return (
    <Box
      component="div"
      className={`border-b ${themeMode === "dark" ? "border-dimgrey text-white" : "border-darkmodegrey text-black"}  cursor-pointer flex items-center gap-[10PX] px-[10px] py-5 shadow-sm
      `}
    >
      <Box
        component="img"
        src={product.productPhoto?.secureUrl}
        alt={product.names[activeLanguage.iso]}
        sx={{
          width: "100px",
          height: "100px",
          borderRadius: "16px",
        }}
      />
      <Box sx={{ flex: 1 }}>
        <Typography variant="h6" component="h2" className="mb-2">
          {productName.toUpperCase()}
        </Typography>

        <Box className="flex items-center justify-between gap-2 mt-2">
          <Typography variant="h6">
            {money(productPrice, currency.prefix)}
          </Typography>

          <ItemAddRemoveInCart
            product={product}
            dispatch={dispatch}
            cart={cart}
            productCount={productCount}
            userSegment={user.segment}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default CartCard;
