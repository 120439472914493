import React, { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  MenuItem,
  Typography,
  Grid,
  CircularProgress,
} from "@mui/material";
import { useThemeContext } from "../theme/ThemeContext";
import axios from "axios";
import { WaitListSubmitState } from "../redux/slices/Client";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import CustomButton from "../v3/components/common/CustomButton";
import { submitWaitlistRequest } from "../helpers/APIHelpers";
import { WaitlistRequest } from "../types/Generic";

interface WaitlistProps {
  open: boolean;
  onClose: () => void;
}

const WaitlistModal: FC<WaitlistProps> = ({ open, onClose }) => {
  const { themeMode } = useThemeContext();
  const [isCountryName, setIsCountryName] = useState<string[]>([]);
  const dispatch = useDispatch();
  const waitlistSubmitState = useSelector(
    (store: RootState) => store.Client.waitlistSubmitState
  );
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    getValues,
  } = useForm<WaitlistRequest>();

  useEffect(() => {
    axios
      .get("https://countriesnow.space/api/v0.1/countries/capital")
      .then((response) => {
        const countryNames = response.data.data.map(
          (country: any) => country.name
        );
        setIsCountryName(countryNames.sort());
      })
      .catch((error) => {
        console.error("Error fetching countries:", error);
      });
  }, []);

  const formSubmit = () => {
    const waitListData = getValues();
    submitWaitlistRequest(waitListData, dispatch);

    if (waitlistSubmitState === WaitListSubmitState.Successful) {
      setTimeout(() => {
        reset();
        onClose();
      }, 3000);
    }
  };

  const backgroundColor = themeMode === "dark" ? "#242424" : "#Fafafa";
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
      sx={{ backgroundColor: "rgba(255, 255, 255, 0.4)" }}
    >
      {waitlistSubmitState === WaitListSubmitState.Requested && (
        <DialogContent className="flex items-center justify-center min-h-60">
          <CircularProgress sx={{ color: "#C34F5A" }} size="80px" />
        </DialogContent>
      )}
      {waitlistSubmitState === WaitListSubmitState.Failed && (
        <>
          <DialogTitle
            className="flex items-center justify-center gap-3 tracking-widest"
            sx={{ paddingTop: "25px" }}
          >
            <Typography variant="h5" component="h5">
              Submission Failed!
            </Typography>
            <CancelIcon sx={{ color: "#B00020" }} />
          </DialogTitle>
          <DialogContent className="flex justify-center">
            <Typography className="text-center">
              Please try again later
            </Typography>
          </DialogContent>
          <DialogActions
            sx={{
              display: "flex",
              justifyContent: "center",
              paddingBottom: "25px",
            }}
          >
            <CustomButton onClick={onClose}>Okay</CustomButton>
          </DialogActions>
        </>
      )}

      {waitlistSubmitState === WaitListSubmitState.Successful && (
        <>
          <DialogTitle
            className="flex items-center justify-center gap-3 tracking-widest"
            sx={{ paddingTop: "25px" }}
          >
            <Typography variant="h5" component="h5">
              Successful!
            </Typography>
            <CheckCircleIcon sx={{ color: "#4BB543" }} />
          </DialogTitle>
          <DialogContent className="flex justify-center">
            <Typography className="text-center">
              Thank you for joining the waitlist,
              <br /> we will get in touch with you soon
            </Typography>
          </DialogContent>
          <DialogActions
            sx={{
              display: "flex",
              justifyContent: "center",
              paddingBottom: "25px",
            }}
          >
            <CustomButton onClick={onClose}>Okay</CustomButton>
          </DialogActions>
        </>
      )}

      {waitlistSubmitState === WaitListSubmitState.Stale && (
        <>
          <DialogTitle sx={{ backgroundColor }}>
            <Typography
              variant="h4"
              component="h4"
              className="tracking-wider text-center"
            >
              Waitlist Form
            </Typography>{" "}
          </DialogTitle>

          <DialogContent sx={{ backgroundColor }}>
            <form onSubmit={handleSubmit(formSubmit)} className="pt-4">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Business Name"
                    {...register("businessName", {
                      required: "Business name is required",
                    })}
                    error={!!errors.businessName}
                    helperText={errors.businessName?.message as React.ReactNode}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        backgroundColor: "initial",
                        "&.Mui-focused": {
                          borderColor: "#a5454fc1",
                        },
                      },
                      "& .MuiOutlinedInput-root.Mui-error fieldset": {
                        borderColor: "#A5454E3F",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Business State"
                    {...register("businessState", {
                      required: "Business state is required",
                    })}
                    error={!!errors.businessState}
                    helperText={
                      errors.businessState?.message as React.ReactNode
                    }
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        backgroundColor: "initial",
                        "&.Mui-focused": {
                          borderColor: "#a5454f8e",
                        },
                      },
                      "& .MuiOutlinedInput-root.Mui-error fieldset": {
                        borderColor: "#A5454E3F",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Business Address"
                    {...register("businessAddress", {
                      required: "Business address is required",
                    })}
                    error={!!errors.businessAddress}
                    helperText={
                      errors.businessAddress?.message as React.ReactNode
                    }
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        backgroundColor: "initial",
                        "&.Mui-focused": {
                          borderColor: "#a5454f8e",
                        },
                      },
                      "& .MuiOutlinedInput-root.Mui-error fieldset": {
                        borderColor: "#A5454E3F",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    select
                    label="Business Country"
                    {...register("businessCountry", {
                      required: "Business country is required",
                    })}
                    error={!!errors.businessCountry}
                    helperText={
                      errors.businessCountry?.message as React.ReactNode
                    }
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        backgroundColor: "initial",
                        "&.Mui-focused": {
                          borderColor: "#a5454f8e",
                        },
                      },
                      "& .MuiOutlinedInput-root.Mui-error fieldset": {
                        borderColor: "#A5454E3F",
                      },
                    }}
                  >
                    {isCountryName.map((country, idx) => (
                      <MenuItem key={idx} value={country}>
                        {country}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Business Email Address"
                    {...register("businessEmail", {
                      required: "Business email is required",
                      pattern: {
                        value:
                          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                        message: "Invalid email address",
                      },
                    })}
                    error={!!errors.businessEmail}
                    helperText={
                      errors.businessEmail?.message as React.ReactNode
                    }
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        backgroundColor: "initial",
                        "&.Mui-focused": {
                          borderColor: "#a5454f8e",
                        },
                      },
                      "& .MuiOutlinedInput-root.Mui-error fieldset": {
                        borderColor: "#A5454E3F",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Business Phone Number"
                    {...register("businessPhoneNumber", {
                      required: "Business phone number is required",
                      pattern: {
                        value: /^\+?\d+$/,
                        message: "Invalid phone number",
                      },
                    })}
                    error={!!errors.businessPhoneNumber}
                    helperText={
                      errors.businessPhoneNumber?.message as React.ReactNode
                    }
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        backgroundColor: "initial",
                        "&.Mui-focused": {
                          borderColor: "#a5454f8e",
                        },
                      },
                      "& .MuiOutlinedInput-root.Mui-error fieldset": {
                        borderColor: "#A5454E3F",
                      },
                    }}
                  />
                </Grid>
              </Grid>
              <DialogActions className="mt-2">
                <Button type="submit" variant="contained" color="primary">
                  Submit
                </Button>
              </DialogActions>
            </form>
          </DialogContent>
        </>
      )}
    </Dialog>
  );
};

export default WaitlistModal;
