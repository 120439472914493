import React from "react";
import { OrderSubmitState } from "../../../../redux/slices/Items";
import { Button } from "@mui/material";

interface Props {
  buttonClickDisabled: boolean;
  canPlaceOrder: boolean;
  currencyPrefix: string;
  orderSubmitState: number;
  orderTotal: string;
  handleSubmit: () => void;
}
export const Footer = (props: Props) => {
  const {
    canPlaceOrder,
    currencyPrefix,
    orderTotal,
    buttonClickDisabled,
    handleSubmit,
    orderSubmitState,
  } = props;

  if (canPlaceOrder) {
    return (
      <>
        {buttonClickDisabled && (
          <RequestReactiveButton requestState={orderSubmitState} />
        )}
        {!buttonClickDisabled && (
          <Button variant="contained" onClick={handleSubmit}>
            Place Order {`${currencyPrefix}${orderTotal}`}
          </Button>
        )}
      </>
    );
  }

  return (
    <>
      <hr color={"#1976D2"} />
      <div>
        <>Total</>: <b>{`${currencyPrefix}${orderTotal}`}</b>
      </div>
      <hr color={"#1976D2"} />
    </>
  );
};

interface RequestButton {
  requestState: number;
}
const RequestReactiveButton = (props: RequestButton) => {
  const { requestState } = props;
  let text = "";
  let buttonColor:
    | "primary"
    | "inherit"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning" = "primary";
  if (requestState === OrderSubmitState.Requested) {
    text = "Placing Order ...";
  } else if (requestState === OrderSubmitState.Successful) {
    text = "Order Successful";
    buttonColor = "success";
  } else if (requestState === OrderSubmitState.Failed) {
    text = "Order Placement Failed";
    buttonColor = "error";
  }

  return (
    <Button variant="contained" color={buttonColor}>
      {text}
    </Button>
  );
};
