import React, { FC } from "react";
import { Dispatch } from "redux";
import { Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { addItemClick } from "../../../../helpers/ActionHelpers";
import { ItemClickAction } from "../../../../helpers/Constants";
import CustomIconButton from "../../../components/common/CustomIconButton";
import { CartItem, ProductInterface } from "../../../../types/Generic";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";

interface AddAndRemoveProps {
  product: ProductInterface | any;
  dispatch: Dispatch<any>;
  cart: CartItem[];
  productCount: number;
  userSegment: string;
}

export const ItemAddRemoveInCart: FC<AddAndRemoveProps> = ({
  product,
  dispatch,
  cart,
  productCount,
  userSegment,
}) => {
  const userHasSegments = useSelector(
    (store: RootState) => store.Client.user.hasSegments
  );
  const productPrice = userHasSegments
    ? product.prices[userSegment]
    : product.price;

  const productWithPrice: ProductInterface = {
    ...product,
    price: productPrice,
  };
  const handleAdd = (e: React.MouseEvent) => {
    e.stopPropagation();
    addItemClick(productWithPrice, dispatch, cart, ItemClickAction.Add);
  };

  const handleRemove = (e: React.MouseEvent) => {
    e.stopPropagation();
    addItemClick(productWithPrice, dispatch, cart, ItemClickAction.Remove);
  };

  return (
    <div className="flex items-center gap-3">
      <div
        className={`${productCount > 0 ? "flex" : "hidden"} items-center gap-2`}
      >
        <CustomIconButton onClick={handleRemove} disabled={productCount === 0}>
          <RemoveIcon />
        </CustomIconButton>
        <Typography variant="h6" component="h6">
          {productCount}
        </Typography>
      </div>
      <CustomIconButton onClick={handleAdd}>
        <AddIcon />
      </CustomIconButton>
    </div>
  );
};

export const AddInCart: FC<AddAndRemoveProps> = ({
  product,
  dispatch,
  cart,
  userSegment,
}) => {
  const productWithPrice = {
    ...product,
    price: product?.prices?.[userSegment],
  };
  const handleAdd = (e: React.MouseEvent) => {
    e.stopPropagation();
    addItemClick(productWithPrice, dispatch, cart, ItemClickAction.Add);
  };

  return (
    <CustomIconButton
      background="rgba(38, 38, 38, 0.4)"
      shadow="0px 6px 12px rgba(164, 69, 78, 0.6)"
      onClick={handleAdd}
    >
      <AddIcon />
    </CustomIconButton>
  );
};

export const RemoveInCart: FC<AddAndRemoveProps> = ({
  product,
  dispatch,
  cart,
  productCount,
  userSegment,
}) => {
  const productWithPrice = {
    ...product,
    price: product?.prices?.[userSegment],
  };
  const handleRemove = (e: React.MouseEvent) => {
    e.stopPropagation();
    addItemClick(productWithPrice, dispatch, cart, ItemClickAction.Remove);
  };

  return (
    <CustomIconButton onClick={handleRemove} disabled={productCount === 0}>
      <RemoveIcon />
    </CustomIconButton>
  );
};
