import AdsClickIcon from "@mui/icons-material/AdsClick";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import React, { FC, useEffect, useRef, useState } from "react";
import CustomModal from "../../../../../components/modal/CustomModalv3";
import {
  AppBar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import CustomButton from "../../../../components/common/CustomButton";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { clearCart, orderSubmit } from "../../../../../helpers/ActionHelpers";
import CartCard from "./CartCard";
import { CartItem } from "../../../../../types/Generic";
import CartBubble from "./CartBubble";
import {
  manageModal,
  MODAL_TRIGGER,
  OrderSubmitState,
} from "../../../../../redux/slices/Items";
import ConfirmModal from "./ConfirmModal";
import { useThemeContext } from "../../../../../theme/ThemeContext";
export enum Confirm {
  Cancel,
  Confirm,
}

interface CartModalProps {
  openCart: boolean;
  closeCart: () => void;
}
const CartModal: FC<CartModalProps> = ({ openCart, closeCart }) => {
  const brandName = useSelector((store: RootState) => store.Client.user.name);
  const cart = useSelector((store: RootState) => store.Items.cart);
  const user = useSelector((store: RootState) => store.Client.user);
  const orderSubmitState = useSelector(
    (store: RootState) => store.Items.orderSubmitState
  );
  const tableNumber = useSelector(
    (store: RootState) => store.Client.tableNumber
  );
  const modal = useSelector((state: RootState) => state.Items.modal);
  const [inputModalOpen, setInputModalOpen] = useState(false);
  const [customerName, setCustomerName] = useState("");
  const dispatch = useDispatch();
  const { themeMode } = useThemeContext();
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const handleInputModal = () => {
    setInputModalOpen(true);
  };
  const handleInputModalClose = () => {
    setInputModalOpen(false);
  };

  const handleModal = () => {
    dispatch(manageModal({ isOpen: true, triggeredBy: MODAL_TRIGGER.CLOSE }));
  };

  const handleConfirmDialog = (response: number) => {
    if (response === Confirm.Confirm) {
      orderSubmit(
        cart,
        user.id,
        user.username,
        dispatch,
        tableNumber,
        customerName
      );
    } else {
      dispatch(
        manageModal({ isOpen: false, triggeredBy: MODAL_TRIGGER.CLOSE })
      );
    }
  };

  const closeSuccessModal = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    dispatch(manageModal({ isOpen: false, triggeredBy: MODAL_TRIGGER.CLOSE }));
    clearCart(cart, dispatch);
  };

  useEffect(() => {
    if (orderSubmitState === OrderSubmitState.Successful) {
      timeoutRef.current = setTimeout(() => {
        clearCart(cart, dispatch);
        dispatch(
          manageModal({ isOpen: false, triggeredBy: MODAL_TRIGGER.CLOSE })
        );
      }, 3000);
    }
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [orderSubmitState, cart, dispatch]);
  return (
    <CustomModal
      open={openCart}
      onClose={closeCart}
      height={"100%"}
      padding={"0px 0px 30px"}
      radius={"0"}
      showCloseButton={false}
      bg="background.default"
      sx={{
        WebkitOverflowScrolling: "scroll",
        viewportFit: "cover",
        boxSizing: "border-box",
      }}
    >
      <AppBar
        component="nav"
        sx={{ minHeight: 70, backgroundColor: "primary.main" }}
      >
        <Toolbar
          className={`sticky top-0 z-10 flex items-center justify-center w-screen h-full p-2 text-white bg-primary min-h-16`}
        >
          <IconButton
            aria-label="close"
            onClick={closeCart}
            sx={{
              position: "absolute",
              left: 5,
              top: 10,
              zIndex: 1000,
              backgroundColor: "#262626",
              borderRadius: "50%",
              "&:hover": {
                backgroundColor: "#666666",
              },
              color: "white",
            }}
          >
            <ArrowBackIcon sx={{ fontSize: "24px" }} />
          </IconButton>
          <div>
            <Typography variant="h4" component="h5" className="text-center">
              Your Cart
            </Typography>
            <Typography variant="body1" component="p" className="text-center">
              {brandName?.toUpperCase()}
            </Typography>
          </div>
        </Toolbar>
      </AppBar>
      <div className="relative w-full h-20 top-20" />
      <div className="grid grid-cols-1 gap-5 px-2 ss:grid-cols-2 md:grid-cols-3">
        {cart.map((item: CartItem) => (
          <CartCard key={item.id} product={item} />
        ))}
      </div>

      {user.canPlaceOrder && (
        <div className="p-5 mb-20 input-name" onClick={handleInputModal}>
          <Typography variant="h4" component="h5">
            Name
          </Typography>
          <div className="flex items-center justify-between gap-2 mt-5">
            <AccountCircleIcon
              sx={{ fontSize: "3rem" }}
              className={`${themeMode === "dark" ? " text-white" : " text-black"}`}
            />
            <div className="flex-1">
              <Typography
                variant="h5"
                className={`${themeMode === "dark" ? " text-white" : " text-black"} mb-2 font-medium`}
              >
                Add Name
              </Typography>
              <Typography
                variant="body2"
                component="h2"
                className={`${themeMode === "dark" ? "text-darkmodegrey" : "text-lightmodegrey"}`}
              >
                click and input your name to make order.
              </Typography>
            </div>
            <AdsClickIcon
              className={`${themeMode === "dark" ? " text-white" : " text-black"}`}
            />
          </div>
        </div>
      )}

      <Dialog
        open={inputModalOpen}
        onClose={handleInputModalClose}
        fullWidth
        maxWidth="sm"
        PaperProps={{
          sx: {
            backgroundColor: "background.default",
            borderRadius: "16px",
            color: "text.primary",
          },
        }}
      >
        <DialogTitle>Enter Your Name</DialogTitle>
        <DialogContent>
          <IconButton
            aria-label="close"
            onClick={handleInputModalClose}
            sx={{
              position: "absolute",
              right: 15,
              top: 15,
              zIndex: 1000,
              backgroundColor: "primary.main",
              borderRadius: "50%",
              "&:hover": {
                backgroundColor: "#666666",
              },
              color: "white",
            }}
          >
            <CloseIcon sx={{ fontSize: "14px" }} />
          </IconButton>{" "}
          <TextField
            autoFocus
            margin="dense"
            label="Name"
            type="text"
            fullWidth
            value={customerName}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setCustomerName(e.target.value)
            }
            sx={{
              "& .MuiInputBase-root": {
                color: "text.secondary",
              },
              "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                border: "2px solid #666",
              },
              "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: "#666",
                },
              "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: "text.primary",
                },
              "& .MuiInputLabel-root": {
                color: "text.primary",
              },
              "& .MuiInputLabel-root.Mui-focused": {
                color: "text.secondary",
              },
            }}
          />
        </DialogContent>
        <DialogActions>
          <CustomButton onClick={handleInputModalClose}>Done</CustomButton>
        </DialogActions>
      </Dialog>

      <CartBubble showCartIcon={false} btnClick={handleModal} />
      <ConfirmModal
        isOpen={modal.isOpen}
        handleClose={closeSuccessModal}
        orderSubmitState={orderSubmitState}
        onConfirm={() => handleConfirmDialog(Confirm.Confirm)}
        onCancel={() => handleConfirmDialog(Confirm.Cancel)}
      />
    </CustomModal>
  );
};

export default CartModal;
