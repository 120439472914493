import React, {
  createContext,
  useContext,
  useState,
  FC,
  useEffect,
} from "react";
import { ThemeProvider, createTheme, Theme } from "@mui/material/styles";
import { get, set } from "lockr";

interface ThemeContextProps {
  themeMode: "light" | "dark";
  toggleTheme: () => void;
  theme: Theme; // Add theme to the context
}

const ThemeContext = createContext<ThemeContextProps>({
  themeMode: "dark",
  toggleTheme: () => {},
  theme: createTheme(), // Provide a default value
});

export const useThemeContext = () => useContext(ThemeContext);

const lightTheme = createTheme({
  palette: {
    primary: {
      main: "#C34F5A",
      light: "#26a69a",
    },
    background: {
      default: "#fafafa",
      paper: "#808080",
    },
    text: {
      primary: "#000",
      secondary: "#666",
    },
  },
  typography: {
    fontFamily: '"Roboto", "Arial", sans-serif',
    fontSize: 16,
    h1: {
      fontSize: "3.375rem",
      fontWeight: 700,
    },
    h2: {
      fontSize: "3rem",
      fontWeight: 700,
    },
    h3: {
      fontSize: "1.875rem",
      fontWeight: 600,
    },
    h4: {
      fontSize: "1.5rem",
      fontWeight: 600,
    },
    h5: {
      fontSize: "1.25rem",
      fontWeight: 600,
    },
    h6: {
      fontSize: "1.125",
      fontWeight: 600,
    },
    body1: {
      fontSize: "1rem",
      fontWeight: 400,
    },
    body2: {
      fontSize: "0.875rem",
      fontWeight: 400,
    },
  },
});

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#C34F5A",
    },
    background: {
      default: "#09090b",
      paper: "#242424",
    },
    text: {
      primary: "#fff",
      secondary: "#ccc",
    },
  },
  typography: {
    fontFamily: '"Roboto", "Arial", sans-serif',
    fontSize: 16,
    h1: {
      fontSize: "3.375rem",
      fontWeight: 700,
    },
    h2: {
      fontSize: "3rem",
      fontWeight: 700,
    },
    h3: {
      fontSize: "1.875rem",
      fontWeight: 600,
    },
    h4: {
      fontSize: "1.5rem",
      fontWeight: 600,
    },
    h5: {
      fontSize: "1.25rem",
      fontWeight: 600,
    },
    h6: {
      fontSize: "1.125",
      fontWeight: 600,
    },
    body1: {
      fontSize: "1rem",
      fontWeight: 400,
    },
    body2: {
      fontSize: "0.875rem",
      fontWeight: 400,
    },
  },
});

export const ThemeContextProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [themeMode, setThemeMode] = useState<"light" | "dark">(
    get("themeMode") || "dark"
  );

  useEffect(() => {
    set("themeMode", themeMode);
  }, [themeMode]);

  const toggleTheme = () => {
    setThemeMode((prevMode) => (prevMode === "dark" ? "light" : "dark"));
  };

  const theme: Theme = themeMode === "dark" ? darkTheme : lightTheme;

  return (
    <ThemeContext.Provider value={{ themeMode, toggleTheme, theme }}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  );
};
