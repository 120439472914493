import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import React from "react";
import { palette } from "../color";
// import {
//   getViewport,
//   useWindowSize,
//   viewports,
// } from "../../helpers/ActionHelpers";

interface StyledProps {}
const StyledWrapper = styled(Box)((props: StyledProps) => ({
  backgroundColor: palette.primaryLighter(0.7),
  // borderBottomLeftRadius: "20px", // Apply border-radius to bottom-left corner
  // borderBottomRightRadius: "20px",
  width: "100%",
  // height: "80px",
  borderRadius: "20px 0 20px 0",
}));

interface Props {
  children: React.ReactNode;
}
const NavBar = (props: Props) => {
  // const windowSize = useWindowSize();
  // const viewport = getViewport(windowSize.width);
  // const containWidth = ![viewports.DESKTOP, viewports.DESKTOP_XL].includes(
  //   viewport
  // );
  // console.log("containWidth", containWidth);

  return <StyledWrapper>{props.children}</StyledWrapper>;
};

export default NavBar;
