import {
  Box,
  Grid,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import CallIcon from "@mui/icons-material/Call";
import WifiIcon from "@mui/icons-material/Wifi";

// Business Segment Model
//menutender/business-name/business-segment
//price to be refactored {segment: price}

export const BusinessInfo = () => {
  const business = useSelector((state: RootState) => state.Client.user);
  return (
    <Box component="section" sx={{ margin: "5px 0px 25px" }}>
      <Typography
        variant="h5"
        sx={{
          marginBottom: "30px",
          textDecoration: "underline wavy #C34F5A",
          textUnderlineOffset: "10px",
        }}
      >
        {business.name}
      </Typography>

      <Grid container gap="20px">
        <Grid container item xs={12}>
          <ListItem disablePadding>
            <ListItemIcon>
              <LocationOnIcon sx={{ color: "primary.main" }} />
            </ListItemIcon>
            <ListItemText primary={business.address} />
          </ListItem>
        </Grid>
        <Grid container item xs={12}>
          <ListItem disablePadding>
            <ListItemIcon>
              <CallIcon sx={{ color: "primary.main" }} />
            </ListItemIcon>
            <ListItemText primary={business.contacts} />
          </ListItem>
        </Grid>

        {business.wifi && (
          <ListItem disablePadding>
            <ListItemIcon>
              <WifiIcon sx={{ color: "primary.main" }} />
            </ListItemIcon>
            <ListItemText primary={business.wifi} />
          </ListItem>
        )}
      </Grid>
      <Grid item mt={3}>
        <Typography variant="body1" sx={{ color: "text.secondary" }}>
          {business.brief}
        </Typography>
      </Grid>
    </Box>
  );
};
