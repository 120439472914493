import LocalBarIcon from "@mui/icons-material/LocalBar";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import Badge from "@mui/material/Badge";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { getCartCount } from "../../helpers/ActionHelpers";
import { BottomNavigation, BottomNavigationAction, Paper } from "@mui/material";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { useEffect, useState } from "react";
import HomeIcon from "@mui/icons-material/Home";
import { getUserBaseSlug } from "../utils/pathUtils";
import { useThemeContext } from "../../theme/ThemeContext";

export const StickyBottomNav = () => {
  const [activePath, setActivePath] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const cart = useSelector((store: RootState) => store.Items.cart);
  const client = useSelector((store: RootState) => store.Client);
  const favorites = useSelector((store: RootState) => store.Items.favorites);
  const cartCount = getCartCount(cart);
  const { user, tableNumber } = client;
  const { themeMode } = useThemeContext();

  const baseSlug = getUserBaseSlug(user, tableNumber);

  useEffect(() => {
    const locationPath = location.pathname.split("/")[3];
    if (!locationPath) {
      setActivePath("home");
    } else {
      setActivePath(locationPath);
    }
  }, [location]);

  const handleNavigation = (navValue: string) => {
    setActivePath(navValue);
    if (navValue === "home") {
      navigate(`/${baseSlug}`);
    } else {
      navigate(`/${baseSlug}/app/${navValue}`);
    }
  };

  return (
    <Paper
      sx={{
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
      }}
      elevation={3}
    >
      <BottomNavigation
        sx={{
          backgroundColor:
            themeMode === "dark" ? "background.paper" : "primary.main",
          minHeight: "80px",
        }}
        showLabels
        value={activePath}
        onChange={(event, newValue) => {
          handleNavigation(newValue);
        }}
      >
        <BottomNavigationAction
          label="Home"
          value="home"
          sx={{
            color: "#fff",
            ".Mui-selected": {
              color: themeMode === "dark" ? "primary.main" : "#fff",
            },
          }}
          icon={
            <HomeIcon
              sx={{ color: themeMode === "dark" ? "primary.main" : "#fff" }}
            />
          }
        />
        <BottomNavigationAction
          label="Products"
          value="products"
          sx={{ color: "#fff" }}
          icon={
            <LocalBarIcon
              sx={{ color: themeMode === "dark" ? "primary.main" : "#fff" }}
            />
          }
        />
        <BottomNavigationAction
          label="Favorites"
          value="favorites"
          sx={{ color: "#fff" }}
          icon={
            <Badge badgeContent={favorites?.length || 0} color="error">
              <FavoriteIcon
                sx={{ color: themeMode === "dark" ? "primary.main" : "#fff" }}
              />
            </Badge>
          }
        />
        <BottomNavigationAction
          label="Cart"
          value="cart"
          sx={{ color: "#fff" }}
          icon={
            <Badge badgeContent={cartCount} color="error">
              <ShoppingCartIcon
                sx={{ color: themeMode === "dark" ? "primary.main" : "#fff" }}
              />
            </Badge>
          }
        />
      </BottomNavigation>
    </Paper>
  );
};
