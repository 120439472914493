import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export enum Confirm {
  Cancel,
  Confirm,
}
interface Props {
  open: boolean;
  handleConfirm: (response: number) => void;
  orderPrice: string;
}
export default function ConfirmOrder(props: Props) {
  const { open, handleConfirm } = props;

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={() => handleConfirm(Confirm.Cancel)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Confirm Order Placement
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please confirm that you want to proceed with placing your order to
            the restaurant.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleConfirm(Confirm.Cancel)}>Cancel</Button>
          <Button onClick={() => handleConfirm(Confirm.Confirm)} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
