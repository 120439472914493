import React from "react";
import {
  Dialog,
  DialogContent,
  IconButton,
  Theme,
  Box,
  SxProps,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface CustomModalProps {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
  radius?: string;
  bg?: string;
  height?: string;
  padding?: string;
  showCloseButton?: boolean;
  sx?: SxProps<Theme>;
}

const CustomModal: React.FC<CustomModalProps> = ({
  open,
  onClose,
  children,
  radius = "30px",
  bg = "background.paper",
  height,
  padding = "50px 20px 20px",
  showCloseButton = true,
  sx,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
      PaperProps={{
        sx: {
          ...sx,
          position: "fixed",
          top: "auto",
          width: "100%",
          borderTopLeftRadius: radius,
          borderTopRightRadius: radius,
          borderBottomRightRadius: "0",
          borderBottomLeftRadius: "0",
          bottom: 0,
          margin: 0,
          backgroundColor: bg,
          minHeight: {
            xs: height || "470px",
            lg: height || "350px",
          },
          animation: "slideUp 0.5s ease",
          "@keyframes slideUp": {
            from: {
              transform: "translateY(100%)",
            },
            to: {
              transform: "translateY(0)",
            },
            "-webkit-overflow-scrolling": "touch",
          },
        },
      }}
    >
      <DialogContent
        className="z-10 no-scrollbar"
        sx={{
          padding,

          display: "flex",
          flexDirection: "column",
          overflowY: "auto",
          "-webkit-overflow-scrolling": "touch",
        }}
      >
        {showCloseButton && (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 15,
              top: 15,
              zIndex: 1000,
              backgroundColor: "#C34F5A",
              borderRadius: "50%",
              "&:hover": {
                backgroundColor: "#666666",
              },
              color: "white",
            }}
          >
            <CloseIcon sx={{ fontSize: "14px" }} />
          </IconButton>
        )}

        <Box className="flex flex-col pb-5 text-white">
          <Box className="content">{children}</Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default CustomModal;
