import React from "react";
import { IconButton as MUIIconButton, SxProps, Theme } from "@mui/material";

interface CustomIconButtonProps {
  children: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  width?: string | number;
  height?: string | number;
  shadow?: string;
  background?: string;
  disabled?: boolean;
  sx?: SxProps<Theme>;
}

const CustomIconButton: React.FC<CustomIconButtonProps> = ({
  children,
  onClick,
  width = "30px",
  height = "30px",
  background = "#A4454E",
  shadow,
  disabled = false,
  sx,
  ...props
}) => {
  return (
    <MUIIconButton
      sx={{
        width,
        height,
        boxShadow: shadow,
        backgroundColor: disabled ? "rgba(38, 38, 38, 0.8)" : background,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "white",
        "&:hover": {
          backgroundColor: "#a4454fdb",
          boxShadow: "0px 3px 6px rgba(164, 69, 79, 0.635)",
        },
        ...sx,
      }}
      disabled={disabled}
      onClick={onClick}
      {...props}
    >
      {children}
    </MUIIconButton>
  );
};

export default CustomIconButton;
