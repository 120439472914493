import React from "react";
import {
  Button as MUIButton,
  ButtonProps as MUIButtonProps,
  SxProps,
  Theme,
} from "@mui/material";

interface CustomButtonProps extends MUIButtonProps {
  sx?: SxProps<Theme>;
  background?: string;
  size?: "small" | "medium" | "large";
  children: React.ReactNode;
}

const CustomButton: React.FC<CustomButtonProps> = ({
  background = "#A4454E",
  size = "large",
  children,
  sx,
  ...props
}) => {
  return (
    <MUIButton
      {...props}
      sx={{
        backgroundColor: background,
        color: "white",
        fontSize: "0.875rem",
        borderRadius: "8px",
        textTransform: "none",
        "&:hover": {
          backgroundColor: `${background}ab`,
        },
        ...sx,
      }}
      size={size}
    >
      {children}
    </MUIButton>
  );
};

export default CustomButton;
